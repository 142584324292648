<template>
  <div class="panel normative">
    <div class="panel-heading">
      <h3>Main Controller Backup</h3>
    </div>
    <div class="panel-content pl-2 pr-2">
      <h4>Latest</h4>
      <template v-if="lastBackup">
        <p>Created: {{lastBackup.created}}</p>
        <p>Size: {{lastBackup.dataSize}} bytes</p>
        <p>SHA256 checksum: {{lastBackup.hashSha256}}</p>
        <b-btn variant="primary" :href="`/api/v1/getPlcBackup/${lastBackup.id}`">Download</b-btn>
      </template>
      <template v-else>
        No backup created yet
      </template>

      <hr>

      <h4>History</h4>
      <table-wrap :query="$options.query" hover no-sort-reset
                  :variables="variables" list-path="deviceById.backupPlcData" :fields="$options.fields" small>
        <template v-slot:top>
          <div class="mb-2 d-flex align-items-center">
            <b-icon icon="arrow90deg-right" shift-v="-4" class="mr-1" />
            <b-btn variant="outline-primary" @click="clickCompare">{{compareButtonText}}</b-btn>
          </div>
        </template>
        <template v-slot:cell(compare)="row">
          <div class="d-flex">
            <b-form-radio name="left" :value="row.item.id" v-model="left" @change="() => changeLeft(row)" />
            <b-form-radio name="right" :value="row.item.id" v-model="right" @change="() => changeRight(row)" />
          </div>
        </template>
        <template v-slot:cell(actions)="row">
          <b-btn variant="primary" class="mr-1" :href="`/api/v1/getPlcBackup/${row.item.id}`">Download</b-btn>
        </template>
      </table-wrap>

      <b-modal title="Compare PLC data" centered v-model="showDiff" lazy size="lg" ok-only ok-title="Close" scrollable no-close-on-backdrop>
        <backup-diff-modal :left="left" :right="right" />
      </b-modal>
    </div>
  </div>

</template>

<script>
import TableWrap       from '@/components/TableWrap';
import BackupDiffModal from '@/components/BackupDiffModal';
import store2          from '@/lib/store2';


export default
{
    apollo:
    {
        $subscribe:
        {
            lastBackup:
            {
                query: require('../../../gql/live/lastPlcBackup.gql'),
                result({data})
                {
                    this.lastBackup = data.deviceById.backupPlcData.nodes[0]
                },
                variables()
                {
                    return { id: this.deviceId, tz: this.$state.timezone }
                }
            }
        }
    },
    components: {BackupDiffModal, TableWrap},
    computed:
    {
        $state() { return store2(this.$pinia)  },
        variables()
        {
            return { id: this.deviceId, tz: this.$state.timezone }
        },
        compareButtonText()
        {
            return `Compare ${this.leftSeqno || '..'} to ${this.rightSeqno || '..'}`;
        }
    },
    created()
    {
        this.$state.topRegular(
            'Main controller backup',
            false
        );
    },
    data()
    {
        return {
            leftSeqno: null,
            rightSeqno: null,
            lastBackup: {},
            left: '',
            right: '',
            showDiff: false
        }
    },
    methods:
    {
        async clickCompare()
        {
            if(this.left === '' || this.right === '')
                await this.$bvModal.msgBoxOk(
                    'Select at least 2 backups to compare',
                    { centered: true }
                );
            else
                this.showDiff = true;
        },
        changeLeft(row)
        {
            this.leftSeqno = row.item.seqno;
        },
        changeRight(row)
        {
            this.rightSeqno = row.item.seqno;
        }
    },
    props:
    {
        deviceId: { type: String, required: true }
    },
    query: require('../../../gql/live/plc_backups.gql'),
    fields:
    [
        {
            key: 'compare',
            label: 'Select',
            thClass: 'min-width'
        },
        {
            key: 'seqno',
            label: 'Number'
        },
        {
            key: 'created',
            label: 'Created'
        },
        {
            key: 'updated',
            label: 'Updated'
        },
        {
            key: 'dataSize',
            label: 'Size',
            formatter: v => `${v} bytes`
        },
        {
            key: 'hashSha256',
            label: 'SHA256 Checksum',
            tdClass: 'text-nowrap'
        },
        {
            key: 'actions',
            label: 'Actions',
            tdClass: 'p-1 text-right',
            thClass: 'text-right'
        }
    ],
    watch:
    {
        deviceId()
        {
            this.left = '';
            this.right = '';
            this.leftSeqno = null;
            this.rightSeqno = null;
        }
    }
}
</script>

<style>
</style>