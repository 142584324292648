<template>
  <div :style="{height: `${height}px`}">
    <canvas ref="chartjsCanvas" />
  </div>
</template>

<script>
import Chart from 'chart.js/auto';

export default
{
    mounted()
    {
        this.instance = new Chart(
            this.$refs.chartjsCanvas,
            {
                data: this.data,
                options: this.options,
                plugins: this.plugins,
                type: this.type
            }
        );
    },
    beforeDestroy()
    {
        if(this.instance)
            this.instance.destroy();
    },
    props:
    {
        data:     { type: Object },
        height:   { type: [Number, String] },
        options:  { type: Object },
        plugins:  { type: Array, default() { return [] } },
        type:     { type: String, default() { return 'line' } }
    },
    watch:
    {
        data:
        {
            deep: true,
            handler(newVal)
            {
                const datasets    = this.instance.data.datasets;
                const labels      = this.instance.data.labels;
                const newDatasets = newVal.datasets;

                datasets.length = newDatasets.length;

                newDatasets.forEach(
                    (newSet, idx) =>
                    {
                        const dataset = datasets[idx];

                        if(dataset === undefined)
                            datasets[idx] = newSet;
                        else
                            Object.assign(dataset, newSet);
                    }
                );

                // labels.length = newVal.labels.length;
                this.instance.data.labels = newVal.labels;

                // newVal.labels.forEach((label, idx) => labels[idx] = label);

                this.instance.update();
            }
        },
        options:
        {
            deep: true,
            handler(newVal)
            {
                this.instance.options = newVal;
                this.instance.update('none');
            }
        }
    }
}
</script>
