<template>
  <div>
    <b-breadcrumb class="d-flex align-items-center pt-1 pb-1">
      <b-breadcrumb-item active>Service</b-breadcrumb-item>
      <b-breadcrumb-item active>Maintenance calendar</b-breadcrumb-item>
      <b-breadcrumb-item></b-breadcrumb-item>
      <div class="" style="width: 35em"><device-multi-selector/></div>
      <li class="ml-auto">
        <b-button-group>
          <b-btn
            v-for="v in $options.views"
            :variant="view === v.value ? 'primary' : 'outline-primary'"
            @click="view = v.value"
            :key="`v_${v.value}`">
            {{v.text}}
          </b-btn>
        </b-button-group>
      </li>
    </b-breadcrumb>
    <div class="panel">
      <div class="panel-heading">
        <h3>Maintenance calendar</h3>
      </div>
      <div class="panel-content">
        <template v-if="view === 'month'">
          <vue-cal
            :disable-views="['years', 'year', 'day', 'week']"
            :events="maintenances"
            :time="false"
            :on-event-click="cellClick"
            active-view="month"
            events-on-month-view="short"
            hide-view-selector />
        </template>
        <template v-if="view === 'matrix'">
          <p class="font-weight-bold">
            Legend:
            <span v-for="(ele, idx) in maintIntervals" class="mr-2">
              <i class="fa fa-fw fa-circle" :style="{color: ele.color}"></i> {{ele.name}}
            </span>
          </p>
          <table class="maintenance-matrix table-responsive">
            <thead>
              <tr>
                <th rowspan="3">E-Crane</th>
                <th class="first" v-for="(month, idx) in matrix.months" :colspan="month.days.length">{{month.name}}</th>
              </tr>
              <tr>
                <template v-for="(week, idx) in matrix.weeks">
                  <th :class="{'first': week.first}" :colspan="week.dayCount">Week {{week.name}}</th>
                </template>
              </tr>
              <tr class="days-row">
                <template v-for="month in matrix.months">
                  <template v-for="(day) in month.days">
                    <th :class="{'first': day.first}">{{day.day}}<br>{{day.dow}}</th>
                  </template>
                </template>
              </tr>
            </thead>
            <tbody>
              <tr v-for="dev in matrix.devices">
                <td><i class="fa fa-fw fa-tag" :style="{color: dev.seriesColor}"></i> {{dev.project}}</td>
                  <template v-for="(day, idx) in matrix.allDays">
                    <td :class="{'first': day.first}">
                      <template v-for="(ele, idx) in matrix.data.get(`${dev.project}-${idx+1}`)">
                        <i class="fa fa-fw fa-circle" @click="cellClick(ele)" :style="{color: ele.maintInterval.color}"></i>
                      </template>
                    </td>
                  </template>
              </tr>
            </tbody>
          </table>
        </template>
        <template v-if="view === 'list'">
          <table class="table table-sm maintenance-list table-hover m-auto valign-middle table-bordered" style="width: auto">
            <thead>
              <tr>
                <th>E-Crane</th>
                <th>Interval</th>
                <th>Last</th>
                <th>Next</th>
                <th>Current</th>
                <th>Estimated due date</th>
                <th colspan="2">Due in</th>
                <th>Actions</th>
              </tr>
              <tr class="small">
                <th></th>
                <th></th>
                <th colspan="3" class="text-center">Operating hours</th>
                <th></th>
                <th>Operating hours</th>
                <th>Calendar days</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
            <tr v-for="entry in maintenances">
              <td><i class="fa fa-fw fa-tag" :style="{color: entry.device.seriesColor}"></i> {{entry.device.project}}</td>
              <td>{{entry.maintInterval.name}}</td>
              <td>{{entry.lastPerformed}}</td>
              <td>{{entry.lastPerformed + entry.maintInterval.frequency}}</td>
              <td>{{entry.ophours}}</td>
              <td>{{entry.start}}</td>
              <td>
                <div class="d-flex align-items-center justify-content-between mr-auto ml-auto" style="width: 5em">
                  <template v-if="entry.dueIn > 48">
                    <i class="fa fa-fw fa-check-circle text-success" style="font-size: 120%"></i>
                  </template>
                  <template v-if="entry.dueIn <= 48 && entry.dueIn > 0">
                    <i class="fa fa-fw fa-exclamation-circle text-warning" style="font-size: 120%"></i>
                  </template>
                  <template v-if="entry.dueIn < 0">
                    <i class="fa fa-fw fa-times-circle text-danger" style="font-size: 120%"></i>
                  </template>
                  {{entry.dueIn}}
                </div>
              </td>
              <td>{{Math.ceil(entry.suggestDiffFromNow)}}</td>
              <td class="p-0"><b-btn variant="link" @click="cellClick(entry)">Detail</b-btn></td>
            </tr>
            </tbody>
          </table>
        </template>
      </div>
    </div>

    <b-modal v-model="showFocus" centered ok-only ok-title="Close" :title-html="focusTitle">
      <maintenance-modal :id="focus"/>
    </b-modal>
  </div>
</template>

<script>
import 'vue-cal/dist/vuecal.css';
import VueCal from 'vue-cal';
import MaintenanceModal from '@/components/MaintenanceModal.vue';
import {CACHE_AND_NETWORK} from '@/lib/constants';
import store2 from '@/lib/store2';
import moment from 'moment';
import DeviceMultiSelector from '@/components/DeviceMultiSelector.vue';

export default
{
    apollo:
    {
        maintenances:
        {
            query: require('../../../gql/query/maintenanceCalendar.gql'),
            result({data})
            {
                this.maintenances = Object.freeze(
                    data.maintenances.nodes.map(
                        row =>
                        {
                            return {
                                ...row,
                                title: `<i class="fa fa-fw fa-tag" style="color: ${row.device.seriesColor}"></i> ${row.device.project}: ${row.maintInterval.name}`,
                                class: 'alert p-0 m-0 alert-primary'
                            }
                        }
                    )
                );
                this.maintIntervals = data.maintIntervals.nodes;
            },
            variables()
            {
                const horizon = moment().add(2, 'years').valueOf();
                let filter;

                if(this.$state.dashboardDevices.length > 0)
                    filter = { and: { deviceId: {in: this.$state.dashboardDevices }, suggest: { lessThan: horizon }} }
                else
                    filter = { suggest: { lessThan: horizon } };

                return {filter, tz: this.$state.timezone};
            },
            fetchPolicy: CACHE_AND_NETWORK,
            manual: true
        }
    },
    components: {DeviceMultiSelector, MaintenanceModal, VueCal },
    computed:
    {
        $state() { return store2(this.$pinia) },
        matrix()
        {
            const months  = [];
            const weeks   = [];
            const devices = new Map();
            const data    = new Map();
            const allDays = [];

            let lastMonth, days, lastDay, key, z, lastWeek, week, day, firstDay, monthWeeks, firstWeek;

            this.maintenances.forEach(
                entry =>
                {
                    if(lastMonth !== entry.matrixMonth)
                    {
                        days = [];
                        monthWeeks = [];
                        months.push({name: entry.matrixMonth, days, weeks: monthWeeks});
                        firstWeek = true;
                        firstDay  = true;
                    }

                    if(lastWeek !== entry.matrixWeek || firstWeek)
                    {
                        week = { name: entry.matrixWeek, dayCount: 0, first: firstWeek };
                        weeks.push(week);
                        monthWeeks.push(week);
                        firstWeek = false;
                    }

                    if(lastDay !== entry.matrixDay || firstDay)
                    {
                        day = {day: entry.matrixDay, dow: entry.suggestDow.substring(0, 2), first: firstDay};
                        allDays.push(day);
                        days.push(day);
                        ++week.dayCount;
                        firstDay = false;
                    }


                    key = `${entry.device.project}-${allDays.length}`;

                    lastMonth = entry.matrixMonth;
                    lastDay   = entry.matrixDay;
                    lastWeek  = entry.matrixWeek;
                    devices.set(entry.device.project, entry.device);
                    z = (data.get(key) || []);
                    z.push(entry);
                    data.set(key, z);
                }
            );

            return Object.freeze({ months, devices: Array.from(devices.values()), data, weeks, allDays });
        }
    },
    data()
    {
        return {
            devices: { nodes:[] },
            focus: null,
            focusTitle: '',
            maintIntervals: [],
            maintenances: [],
            showFocus: false,
            view: 'month'
        }
    },
    methods:
    {
        cellClick(entry)
        {
            this.focusTitle = entry.title;
            this.focus      = entry.id;
            this.showFocus  = true;
        }
    },
    views:
    [
        { text: 'Month', value: 'month' },
        { text: 'Matrix', value: 'matrix' },
        { text: 'List', value: 'list' }
    ]
}
</script>

<style lang="scss">
.vuecal--month-view .vuecal__cell
{
    height: 110px;
    padding: .2em;
}

.vuecal__event:hover
{
    cursor: pointer
}


.vuecal--month-view .vuecal__cell-content
{
    justify-content: flex-start;
    height: 100%;
    overflow: auto;
    align-items: flex-end;
}

.vuecal--month-view .vuecal__event-title
{
    font-size: inherit;
}

.maintenance-matrix
{
    white-space: nowrap;
    max-height: 70vh;
    border-collapse: separate;
    border-spacing: 0;
}

.maintenance-matrix thead tr:nth-child(1) th:nth-child(1),
.maintenance-matrix thead
{
    background: white;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 10;
}

.maintenance-matrix tbody tr td:nth-child(1)
{
    background: white;
    position: sticky;
    left: 0;
    top: 0;
    z-index: 5;
}

.maintenance-matrix td
{
    padding: 0.3rem;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
}

.maintenance-matrix th
{
    padding: 0.3rem;
    border-bottom: 1px solid #dee2e6;
    border-left: 1px solid #dee2e6;
}

.maintenance-matrix thead tr:nth-child(1) th
{
    border-top: 1px solid #dee2e6;
}

.maintenance-matrix td:last-child
{
    border-right: 1px solid #dee2e6;
}

.maintenance-matrix th:last-child
{
    border-right: 1px solid #dee2e6;
}

.maintenance-matrix tr.days-row
{
    text-align: center;
}

.maintenance-matrix .first
{
    border-left-style: double !important;
    border-left-width: 3px !important;
    border-left-color: #dee2e6 !important;
}

.maintenance-matrix tbody td:not(:nth-child(1))
{
    text-align: center;
}

.maintenance-matrix i
{
    cursor: pointer;
}

.maintenance-list
{
    text-align: center;
}

.maintenance-list tr th:nth-child(2),
.maintenance-list tr td:nth-child(2),
.maintenance-list tr th:nth-child(1),
.maintenance-list tr td:nth-child(1)
{
    text-align: left;
}

.maintenance-list tr th:nth-child(7)
{
    /*width: 7em;*/
    text-align: center;
}
</style>