<template>

  <div class="row">
    <div class="col-12 col-sm-6 col-md-4 col-lg-3 col-xl2-2" v-for="({node}) in devices.edges">
      <mini-crane-card :id="node.id"/>
    </div>
  </div>

</template>

<script>

import MiniCraneCard from '../../../components/MiniCraneCard';
import store2        from '@/lib/store2';

export default
{
    apollo:
    {
        devices:
        {
            query: require('../../../gql/dashboardFull.gql'),
            variables()
            {
                if(this.$state.dashboardDevices.length === 0)
                    return {};
                else
                    return { filter: { deviceId: { in: this.$state.dashboardDevices } } };
            }
        }
    },
    components: { MiniCraneCard },
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    data()
    {
        return {
            devices: { edges: [] }
        }
    }
}
</script>

<style>
</style>