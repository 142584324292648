import moment from 'moment';

export const DATE_INPUT_FMT = 'YYYY-MM-DD';

export default function dateComputed(model, key = null)
{
    if(model && key)
    {
        return {
            get() { return moment(this[model][key]).format(DATE_INPUT_FMT) },
            set(v)
            {
                if(v === '')
                    return;

                const parsed    = moment(v, DATE_INPUT_FMT);
                const original  = moment(this[model][key]);

                original.year(parsed.year());
                original.month(parsed.month());
                original.date(parsed.date());

                this[model][key] = original.valueOf();
            }
        }
    }
    else if(model)
    {
        return {
            get() { return moment(this[model]).format(DATE_INPUT_FMT) },
            set(v)
            {
                if(v === '')
                    return;

                const parsed    = moment(v, DATE_INPUT_FMT);
                const original  = moment(this[model]);

                original.year(parsed.year());
                original.month(parsed.month());
                original.date(parsed.date());

                this[model] = original.valueOf();
            }
        }
    }
}
