<template>
  <div>
    <b-breadcrumb class="d-none d-sm-flex">
      <b-breadcrumb-item active>Service</b-breadcrumb-item>
      <b-breadcrumb-item active to="/app/oilSamples">Oil samples</b-breadcrumb-item>
    </b-breadcrumb>

    <single-stat class="mt-2 mb-2"></single-stat>

    <div class="panel normative">
      <div class="panel-heading">
        <h3>Oil samples</h3>
      </div>
      <div class="panel-content">
        <p class="d-none d-sm-block">
          <b-btn variant="primary" to="/app/oilSamples/request">Request oil analysis</b-btn>
        </p>
        <table-wrap :query="$options.query" :fields="$options.fields" hover class="oil-quality-list" table-class="normative" :state.sync="$state.oilAnalysisTableState"
                    @row-selected="rowSelected"
                    selectable no-select-on-click selected-variant="info" ref="l" @singleTap="singleTap">
          <template #cell(mobile)="{item}">
            <div class="d-flex">
              <strong class="flex-grow-1">{{item.ourRef}}</strong>
              <span>{{$options.OilSampleStatus[item.status].text}}</span>
            </div>
            <div class="d-flex">
              <div class="flex-grow-1">{{item.device.project}}</div>
              <span v-html="$options.OilSampleTestCondition[item.oilSampleTestResult?.condition]?.html">-</span>
            </div>
          </template>
          <template #cell(actions)="row">
            <b-btn :to="`/app/oilSamples/${row.item.id}`" class="mr-1">Details</b-btn>
            <b-btn v-show="row.item.status === $options.OilSampleStatus.REQUESTED.value" variant="primary" @click="sentToLab = row.item; showSentToLab = true" style="width: 8em;">Send to lab</b-btn>
            <b-btn v-show="row.item.status === $options.OilSampleStatus.SENT_TO_LAB.value" variant="primary-alt" @click="complete = row.item; showComplete = true" style="width: 8em;">Add test result</b-btn>
            <b-btn v-show="row.item.status === $options.OilSampleStatus.COMPLETED.value" variant="link" :href="`/api/v1/getOilAnalysisReport/${row.item.id}/${row.item.oilSampleTestResult?.pdfFilename}`" target="_blank" style="width: 8em;">Test report <i class="fa fa-fw fa-external-link"></i></b-btn>
          </template>
        </table-wrap>
      </div>

      <b-modal v-model="showComplete" no-close-on-backdrop centered :title="`Complete oil analysis ${complete.ourRef}`" lazy @hide="$refs.l.clearSelected()">
        <complete-oil-analysis-modal ref="completeModal" :id="complete.id"/>
        <template #modal-footer="{ok, cancel}">
          <b-btn variant="outline-secondary" @click="cancel">Cancel</b-btn>
          <busy-btn variant="primary" @click="$refs.completeModal.submitComplete(ok)">Complete analysis</busy-btn>
        </template>
      </b-modal>

      <b-modal v-model="showSentToLab" no-close-on-backdrop centered :title="`Update oil analysis ${sentToLab.ourRef}`" lazy @hide="$refs.l.clearSelected()">
        <send-oil-sample-to-lab-modal :id="sentToLab.id" ref="sentToLabModal" />
        <template #modal-footer="{ok, cancel}">
          <b-btn variant="outline-secondary" @click="cancel">Cancel</b-btn>
          <busy-btn variant="primary" @click="$refs.sentToLabModal.submitComplete(ok)">Advance to “sent to lab” status</busy-btn>
        </template>
      </b-modal>
      <f-a-b to="/app/oilSamples/request" class="d-sm-none" />
    </div>
  </div>

</template>

<script>
import CompleteOilAnalysisModal     from '@/components/CompleteOilAnalysisModal.vue';
import FAB                          from '@/components/FAB.vue';
import OilSampleStatus              from '@/lib/enums/OilSampleStatus.mjs';
import OilSampleTestCondition       from '@/lib/enums/OilSampleTestCondition';
import SendOilSampleToLabModal      from '@/components/SendOilSampleToLabModal.vue';
import SteppedProgress              from '@/components/SteppedProgress.vue';
import TableWrap                    from '@/components/TableWrap.vue';
import store2                       from '@/lib/store2';
import SingleStat                   from '@/components/SingleStat.vue';

const empty = {};

export default
{
    components: {FAB, SendOilSampleToLabModal, SteppedProgress, CompleteOilAnalysisModal, TableWrap, SingleStat},
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    created()
    {
        this.$state.topRegular(
            '',
            false,
            [
                {
                    condition: list => list.length === 1,
                    icon: 'paper-plane',
                    click: rows =>
                    {
                        this.showSentToLab = true;
                        this.sentToLab = rows[0];
                    },
                },
                {
                    condition: list => list.length === 1,
                    icon: 'check',
                    click: rows =>
                    {
                        this.showComplete = true;
                        this.complete = rows[0];
                    }
                }
            ],
            () => this.$refs.l.clearSelected()
        );
    },
    data()
    {
        return {
            complete: empty,
            showComplete: false,
            showSentToLab: false,
            sentToLab: empty
        }
    },
    methods:
    {
        rowSelected(rows)
        {
            if(rows.length === 0)
                this.$state.topReturnToRegular();
            else
                this.$state.topSelectWContext(rows);
        },
        singleTap(e)
        {
            return this.$router.push(`/app/oilSamples/${e.id}`);
        }
    },
    query: require('../../../gql/live/oilList.gql'),
    fields:
    [
        {
            label: '',
            key: 'mobile'
        },
        {
            label: 'Reference',
            key: 'ourRef',
            search: TableWrap.SEARCH_STRING,
            sortable: true,
            sort: 'OUR_REF',
            tdClass: 'text-monospace'
        },
        {
            label: 'Status',
            key: 'status',
            search: TableWrap.SEARCH_ENUM,
            values: Object.keys(OilSampleStatus),
            sortable: true,
            sort: 'STATUS',
            formatter(value)
            {
                return OilSampleStatus[value].text;
            },
        },
        {
            label: 'Requested on',
            key: 'created',
            sortable: true,
            sort: 'CREATED',
            initialSortDesc: true
        },
        {
            label: 'Requested by',
            key: 'userByCreatedBy.displayName',
            sortable: true,
            sort: 'USER_BY_CREATED_BY__DISPLAY_NAME'
        },
        {
            label: 'Project',
            key: 'device.project',
            search: TableWrap.SEARCH_STRING,
            sortable: true,
            sort: 'DEVICE_BY_DEVICE_ID__PROJECT'
        },
        {
            label: 'Crane serial',
            key: 'device.craneSerial',
            search: TableWrap.SEARCH_STRING,
            sortable: true,
            sort: 'DEVICE_BY_DEVICE_ID__CRANE_SERIAL'
        },
        {
            label: 'Sampling point',
            key: 'samplingPoint.displayName',
            search: TableWrap.SEARCH_STRING,
        },
        {
            label: 'Condition',
            key: 'oilSampleTestResult.condition',
            sortable: true,
            sort: 'OIL_SAMPLE_TEST_RESULT_BY_OIL_SAMPLE_ID__CONDITION',
            /*search: TableWrap.SEARCH_ENUM,
            values: Object.values(OilSampleTestCondition).map(e => e.value),*/
            tdClass(value)
            {
                return OilSampleTestCondition[value]?.class;
            },
            formatter(value)
            {
                return OilSampleTestCondition[value]?.text;
            }
        },
        {
            label: 'Actions',
            key: 'actions',
            tdClass: 'p-1 text-right',
            thClass: 'text-right'
        }
    ],
    OilSampleTestCondition,
    OilSampleStatus,
    oilStatsQuery: require('@/gql/live/oilSampleStats.gql')
}
</script>

<style lang="scss">
.oil-quality-list table
{
    thead
    {
        tr
        {
            th:nth-child(2) { width: 10em; } // Reference
            th:nth-child(3) { width: 10em; } // Status
            th:nth-child(4) { width: 9em; } // Requested on
            th:nth-child(5) { width: 11em; } // Requested by
            th:nth-child(6) { width: 10em; } // Project
            th:nth-child(7) { width: 12em; } // Sampling point
            // Crane serial
            td:nth-child(9) { width: 14em; } // Condition
            th:nth-child(10) { width: 1% } // Actions
        }
    }
    tbody
    {
        tr
        {
            td:nth-child(1)
            {
                flex-direction: column;
                div { margin-left: 0 }
            }
            td:nth-child(3) { max-width: 10em; }
            td:nth-child(8) { width: 14em; max-width: 14em; }
            td:nth-child(10) {  white-space: nowrap }
        }
    }

}

.crane-serial-cell
{
  div
  {
    width: 22px;
    display: inline-block;
    img
    {
      border: 1px solid #cccccc;
      vertical-align: text-top;
      height: 15px;
    }
  }
}
</style>
