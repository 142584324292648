<template>
  <div>
    <b-breadcrumb class="d-none d-sm-flex">
      <b-breadcrumb-item disabled>Service</b-breadcrumb-item>
      <b-breadcrumb-item to="/app/oilSamples">Oil samples</b-breadcrumb-item>
      <b-breadcrumb-item active>Details {{oilSampleById?.ourRef}}</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container>
      <b-row>
        <b-col>
          <div class="panel normative">
            <div class="panel-heading">
              <h3>Oil sample details</h3>
            </div>
            <div class="panel-content">
              <vue-element-loading :active="loading" spinner="spinner"/>
              <b-alert v-if="oilSampleById === null" variant="danger">
                Well this is awkward... No oil sample found with this ID.
              </b-alert>
              <template v-else-if="oilSampleById">
                <p class="panel-footer-actions">
                  <b-btn :to="labelHref" target="_blank">View label <i class="fa fa-external-link"></i></b-btn>
                  <busy-btn @click="printLabel" class="ml-1">Print label</busy-btn>
                  <b-btn :to="`/app/oilSamples/${id}/update`" :disabled="!$can('update', oilSampleById)" class="ml-1">Update</b-btn>
                  <busy-btn @click="reparse" class="ml-1" :disabled="!$can('reparseOilTestResult', oilSampleById)">Reparse oil test result</busy-btn>
                </p>
                <stepped-progress :steps="Object.values($options.OilSampleStatus)" :value="oilSampleById.status"/>
                <h4>Test results</h4>
                <table class="oil-analysis-details-table table table-sm">
                  <template v-if="oilSampleById.status === $options.OilSampleStatus.COMPLETED.value">
                    <tr>
                      <td>Condition</td>
                      <td v-html="$options.OilSampleTestCondition[oilSampleById.oilSampleTestResult.condition].html"></td>
                    </tr>
                    <tr>
                      <td>Provider</td><td>{{oilSampleById.sentToLab?.oilAnalysisProvider?.displayName||'-'}}</td>
                    </tr>
                    <tr>
                      <td>Analysis date</td><td>{{oilSampleById.oilSampleTestResult?.analyzed||'-'}}</td>
                    </tr>
                    <tr>
                      <td>Report uploaded filename</td><td class="text-break">{{oilSampleById.oilSampleTestResult?.pdfOriginalFilename||'-'}}</td>
                    </tr>
                    <tr>
                      <td>Report</td>
                      <td>
                        <b-link v-if="oilSampleById.status === 'COMPLETED'" :href="`/api/v1/getOilAnalysisReport/${oilSampleById.id}/${oilSampleById.oilSampleTestResult?.pdfFilename}`" target="_blank">Open PDF <i class="fa fa-fw fa-external-link"></i></b-link>
                        <span v-else>-</span>
                      </td>
                    </tr>
                  </template>
                  <template v-else>
                    <tr>
                      <td colspan="2">Not tested yet</td>
                    </tr>
                  </template>
                </table>
                <h4>Properties</h4>
                <table class="oil-analysis-details-table table table-sm">
                  <tr>
                    <td>Our reference</td><td class="text-monospace">{{oilSampleById.ourRef}}</td>
                  </tr>
                  <tr>
                    <td>Status</td><td>{{$options.OilSampleStatus[oilSampleById.status].text}}</td>
                  </tr>
                  <tr>
                    <td>Origin</td><td>{{oilSampleById.origin}}</td>
                  </tr>
                  <tr>
                    <td>Crane</td>
                    <td class="crane-serial-cell"><div><img :src="oilSampleById.device.country.flag" :alt="oilSampleById.device.country.al2"></div>{{oilSampleById.device.craneSerial}}</td>
                  </tr>
                  <tr>
                    <td>Series</td>
                    <td><i class="fa fa-fw fa-tag" :style="`color: ${oilSampleById.device.seriesColor}`"></i>{{oilSampleById.device.series}}</td>
                  </tr>
                  <tr>
                    <td>Project</td><td>{{oilSampleById.device.project}}</td>
                  </tr>
                  <tr>
                    <td>Oil</td><td>{{oilSampleById.oil.displayName}}</td>
                  </tr>
                  <tr>
                    <td>Sampled (SD)</td><td>{{oilSampleById.sampled}}</td>
                  </tr>
                  <tr>
                    <td>Sampling point (SP)</td><td>{{oilSampleById.samplingPoint.displayName}}</td>
                  </tr>
                  <tr>
                    <td>Operating time machine</td><td>{{oilSampleById.optimeMachine}} hours</td>
                  </tr>
                  <tr>
                    <td>Operating time oil</td>
                    <td v-if="oilSampleById.optimeOil">{{oilSampleById.optimeOil}} hours</td>
                    <td v-else><i>Unknown</i></td>
                  </tr>
                  <tr>
                    <td>Oil change</td><td>{{oilSampleById.oilChange}}</td>
                  </tr>
                  <tr>
                    <td>Filter change</td><td>{{oilSampleById.filterChange}}</td>
                  </tr>
                  <tr>
                    <td>Options</td><td>{{oilSampleById.options}}</td>
                  </tr>
                  <tr>
                    <td>Requested by</td><td>{{oilSampleById.userByCreatedBy.displayName}}</td>
                  </tr>
                  <tr>
                    <td>Remarks</td><td>{{oilSampleById.requestComment}}</td>
                  </tr>
                </table>
                <h4>History for {{oilSampleById.device.craneSerial}}</h4>
                <b-form-group label="Sampling point">
                  <b-form-radio-group v-model="samplingPointId" :options="samplingPointOptions" stacked @change="samplePointChanged" />
                </b-form-group>

                <template v-if="!loading && oilSampleById.device.oilSamples.totalCount === 0">
                  No oil samples test results found
                </template>
                <template v-else>
                  <b-row>
                    <template v-for="chart in charts">
                      <b-col cols="12" xl="6">
                        <h5 class="mb-0 mt-2">{{chart.chartData.options.title.text}}</h5>
                        <chart-js :data="chart.chartData.data" :options="chartOptions(chart)" :plugins="$options.plugins" height="200" />
                      </b-col>
                    </template>
                  </b-row>
                </template>
              </template>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>

import SteppedProgress                   from '@/components/SteppedProgress.vue';
import {CACHE_AND_NETWORK, NETWORK_ONLY} from '@/lib/constants';
import OilSampleTestCondition            from '@/lib/enums/OilSampleTestCondition';
import OilSampleStatus                   from '@/lib/enums/OilSampleStatus.mjs';
import store2                            from '@/lib/store2';
import ChartJs                           from '@/components/ChartJs.vue';


export default
{
    apollo:
    {
        oilSampleById:
        {
            query: require('../../../gql/query/oilSampleDetail.gql'),
            variables() { return { id: this.id } },
            fetchPolicy: CACHE_AND_NETWORK,
            result({data})
            {
                this.$state.topSubtitle = data.oilSampleById?.ourRef;
                this.loading = false;
                const labelHref = `/api/v1/getOilSampleLabel/${this.id}/${data.oilSampleById?.labelFilename}`;
                this.$state.setTopActions(
                    { icon: 'tag', href: labelHref },
                    { icon: 'print', click: this.printLabel },
                    { icon: 'pencil', to: () => `/app/oilSamples/${this.id}/update`, disabled: !this.$can('update', data.oilSampleById) }
                );
                this.labelHref = labelHref;

                if(!data.oilSampleById)
                    return;

                this.samplingPointOptions   = data.oilSampleById.device.samplingPoints.nodes;
                this.samplingPointId        = data.oilSampleById.samplingPoint.id;
                this.charts                 = JSON.parse(data.oilSampleById.device.oilSampleCharts);
            }
        },
    },
    components: {ChartJs, SteppedProgress},
    computed:
    {
        $state() { return store2(this.$pinia) },
    },
    created()
    {
    },
    data()
    {
        return {
            charts: [],
            oilSampleById: undefined,
            loading: true,
            labelHref: '',
            samplingPointOptions: [],
            samplingPointId: ''
        }
    },
    methods:
    {
        async samplePointChanged(samplingPointId)
        {
            const { data } = await this.$apollo.query(
                {
                    query: require('@/gql/query/oilSampleCharts.gql'),
                    variables: { id: this.id, samplingPointId },
                    fetchPolicy: NETWORK_ONLY
                }
            );

            this.charts = JSON.parse(data.oilSampleById.device.oilSampleCharts);
        },
        async printLabel()
        {
            const { data: { printOilSampleLabel } } = await this.$apollo.mutate(
                {
                    mutation: require('@/gql/mutations/printOilSampleLabel.gql'),
                    variables: { id: this.id },
                }
            );

            if(printOilSampleLabel.printOk)
            {
                await this.$bvModal.msgBoxOk('Print job sent to label printer', {centered: true});
            }
            else
            {
                await this.$bvModal.msgBoxOk('Print job failed', {centered: true});
                console.error(printOilSampleLabel);
            }
        },
        async reparse()
        {
            const { data: { reparseOilTestResult } } = await this.$apollo.mutate(
                {
                    mutation: require('@/gql/mutations/reparseOilTestResult.gql'),
                    variables: { id: this.id },
                }
            );

            const result = reparseOilTestResult.oilSampleTestResult.pdfParseResult;

            const msg = result === null ? 'Test result reparsed successfully' : `Reparse failed: ${result}`;

            this.$apollo.queries.oilSampleById.refresh();

            return this.$bvModal.msgBoxOk(
                msg,
                {
                    centered: true,
                    title: 'Reparse oil test result'
                }
            );
        },
        chartOptions({chartOptions: { refIdx, unit }})
        {
            return {
                responsive: true,
                maintainAspectRatio: false,
                scales:
                {
                    x: { offset: true },
                    y: { beginAtZero: true, title: {display: true, text: unit }, afterFit(scale) { scale.width = 62;} }
                },
                elements:
                {
                    point: { radius: v => v.dataIndex === refIdx ? 4 : 3 }
                },
                plugins:
                {
                    legend: { labels: { boxWidth: 12 } }
                }
            };
        }
    },
    props:
    {
        id: { type: String, required: true }
    },
    OilSampleTestCondition,
    OilSampleStatus,
    plugins:
    [
        {
            beforeInit(chart)
            {
                const originalFit = chart.legend.fit;

                chart.legend.fit = function()
                {
                    originalFit.bind(chart.legend)();
                    this.height += 15;
                }
            }
        }
    ]
}
</script>

<style lang="scss">
.oil-analysis-details-table
{
    tr
    {
        td { width: 50%; }
    }
    margin-left: auto;
    margin-right: auto;
}
</style>