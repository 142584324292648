<template>
  <treeselect :multiple="true" :options="devices.nodes" :limit="1" v-model="selected" :match-keys="['label', 'project']" />
</template>

<script>

import Treeselect from '@riophae/vue-treeselect';
import '@riophae/vue-treeselect/dist/vue-treeselect.css'

import store2     from '@/lib/store2';

export default
{
    apollo:
    {
        devices:
        {
            query: require('../gql/query/dashboard_crane_select_dropdown.gql'),
        }
    },
    components: {Treeselect},
    computed:
    {
        $state() { return store2(this.$pinia) },
        selected:
        {
            get() { return this.$state.dashboardDevices },
            set(v) { return this.$state.setDashboardDevices(this, v) }
        }
    },
    data()
    {
        return { devices: { nodes: [] }}
    }
}
</script>

<style>
</style>