<template>
  <b-container id="splash" class="splash-container">

    <public-head/>

    <div class="row no-gutters">
      <div class="jumbotron">
        <h1 class="display-4">E-CARE</h1>
        <p class="lead">Monitor your E-Crane's performance and maintenance metrics in real-time</p>
        <h2>E-Crane Analysis and Reporting</h2>
        <div class="row mt-4">
          <div class="col-sm-6 mb-3">
            <b-carousel img-width="350" img-height="200" fade>
              <b-carousel-slide img-src="/img/ecare_demo_1.png"></b-carousel-slide>
              <b-carousel-slide img-src="/img/ecare_demo_2.png"></b-carousel-slide>
              <b-carousel-slide img-src="/img/ecare_demo_3.png"></b-carousel-slide>
              <b-carousel-slide img-src="/img/ecare_demo_4.png"></b-carousel-slide>
              <b-carousel-slide img-src="/img/ecare_demo_5.png"></b-carousel-slide>
            </b-carousel>
          </div>
          <div class="col-sm-6 text-left">
            <p class="font-weight-bold mb-0">Productivity</p>
            <p>See your E-Crane productivity: tonnes handled, safe working load, cycle times</p>
            <p class="font-weight-bold mb-0">Alarms</p>
            <p>Follow-up of alarms</p>
            <p class="font-weight-bold mb-0">Reporting</p>
            <p>Deliver point-in-time reports</p>
            <p class="font-weight-bold mb-0">Analysis</p>
            <p>Keep watch over your E-Crane performance in great detail</p>
          </div>
        </div>
      </div>
    </div>

    <public-foot/>

  </b-container>
</template>

<script>

import PublicFoot from '@/components/public/PublicFoot';
import PublicHead from '@/components/public/PublicHead';
export default
{
    components: {PublicHead, PublicFoot}
}
</script>