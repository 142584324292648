import Router from 'vue-router'

import NoDeviceSelected from '../components/NoDeviceSelected';

import Splash from '@/Main/Splash/Splash'
import App from '@/App';


import DashboardFull from '../views/app/home/DashboardFull';
import Dashboard     from '../views/app/home/Dashboard';
import DashboardMini from '../views/app/home/DashboardMini';
import DashboardMap  from '../views/app/home/DashboardMap';
import LegalCompliance from '@/components/LegalCompliance';
import MainControllerBackup from '@/views/app/service/MainControllerBackup.vue';
import AndroidLikeTopbar from '@/components/AndroidLikeTopbar.vue';
import QRA from '@/components/QRA.vue';
import OilSampleDetails from '@/views/app/service/OilSampleDetails.vue';
import OilSampleUpdate from '@/views/app/service/OilSampleUpdate.vue';
import OilSampleRequest from '@/views/app/service/OilSampleRequest.vue';
import OilSamples from '@/views/app/service/OilSamples.vue';
import MaintenanceCalendar from '@/views/app/service/MaintenanceCalendar.vue';
import ISO15143            from '@/views/app/integrations/ISO15143.vue';

export default new Router(
    {
        mode: 'history',
        routes:
        [
            {
                path: '/app',
                component: App,
                children:
                [
                    {
                        path: '',
                        redirect: 'dashboard/full'
                    },
                    {
                        path: 'apiKeys',
                        component: () => import('../App/APIKeys/APIKeysList'),
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'annotations',
                        component: () => import('../App/Annotations/AnnotationsList'),
                        props: true,
                        meta: { requiresAuth: true }
                    },
                    {
                        path: 'alarms',
                        component: () => import('../App/Alarms/Alarms'),
                        props: true,
                        meta: { requiresAuth: true },
                        children:
                        [
                            { path: '', component: NoDeviceSelected, props: false },
                            { path: 'device/:deviceId', component: () => import('../App/Alarms/AlarmView'), props: true}
                        ]
                    },
                    {
                        path: 'cycles',
                        component: () => import('../App/Cycles/Cycles'),
                        props: true,
                        meta: { requiresAuth: true },
                        children:
                        [
                            { path: '', component: NoDeviceSelected, props: false },
                            { path: 'device/:deviceId', component: () => import('../App/Cycles/CyclesView'), props: true }
                        ]
                    },
                    {
                        path: 'enroll',
                        component: () => import('../App/Enroll.vue')
                    },
                    {
                        path: 'deviceDetail',
                        component: () => import('@/App/Home/DeviceDetail.vue'),
                        props: true,
                        meta: { requiresAuth: true },
                        children:
                        [
                            { path: '', component: NoDeviceSelected, props: false },
                            { path: ':deviceId', component: () => import('../App/Home/Dashboard/NoDashboardSelected'), props: false },
                            { path: ':deviceId/dashboard/:dashboardId', component: () => import('@/components/DashboardView'), props: true }
                        ]
                    },
                    {
                        path: 'deviceOverview/:deviceId',
                        component: () => import('@/App/DeviceOverview'),
                        props: true,
                        meta: { requiresAuth: true },
                        children:
                        []
                    },
                    {
                        path: 'view/:systemId',
                        component: () => import('@/components/SystemDashboard'),
                        meta: { requiresAuth: true },
                        props: true,
                        children:
                        [
                            {
                                path: ':deviceId',
                                props: true,
                                component: () => import('@/components/DashboardView'),
                            }
                        ]
                    },
                    {
                        path: 'devices',
                        component: () => import('../App/Devices/DeviceList'),
                        meta: { requiresAuth: true },
                    },
                    {
                        path: 'reportHistory',
                        component: () => import('@/Main/ReportHistory/ReportHistory'),
                        meta: { requiresAuth: true },
                        children:
                        [
                            { path: '', component: () => import('@/components/ReportHistoryList'), props: false },
                            { path: ':id', name: 'viewReport', component: () => import('@/components/ReportView.vue'), props: true }
                        ]
                    },
                    {
                        path: 'static',
                        component: () => import('@/components/StaticContainer'),
                        meta: { requiresAuth: true },
                        props: true,
                        children:
                        [
                            {
                                path: 'documentation/:deviceId',
                                component: () => import('@/App/Documents.vue'),
                                meta: { breadcrumb: 'Documentation' },
                                props: true
                            },
                            {
                                component: MainControllerBackup,
                                meta: { breadcrumb: 'Main controller backup' },
                                path: 'mainControllerBackup/:deviceId',
                                props: true
                            }
                        ]
                    },
                    { path: 'oilSamples', component: OilSamples, meta: { topTitle: 'Oil samples' } },
                    {
                        path: 'oilSamples/request',
                        component: OilSampleRequest,
                        meta:
                        {
                            topType: AndroidLikeTopbar.TOPTYPE_INCONTEXT,
                            topTitle: 'Oil analysis request',
                            topReturnTo: '/app/oilSamples'
                        }
                    },
                    {
                        path: 'oilSamples/:id',
                        component: OilSampleDetails,
                        meta:
                        {
                            topType: AndroidLikeTopbar.TOPTYPE_INCONTEXT,
                            topTitle: 'Oil sample details',
                            topReturnTo: '/app/oilSamples',
                            topSubtitle: 'Loading...'
                        },
                        props: true
                    },
                    {
                        path: 'oilSamples/:id/update',
                        component: OilSampleUpdate,
                        meta:
                        {
                            topType: AndroidLikeTopbar.TOPTYPE_INCONTEXT,
                            topTitle: 'Update oil sample',
                            topReturnTo: '..'
                        },
                        props: true
                    },
                    {
                        path: 'maintenanceCalendar',
                        component: MaintenanceCalendar,
                        meta: {}
                    },
                    {
                        path: 'iso15143',
                        component: ISO15143,
                        meta: {}
                    },
                    {
                        name: 'dashboard',
                        path: 'dashboard',
                        component: Dashboard,
                        meta: { requiresAuth: true },
                        redirect: 'dashboard/full',
                        children:
                        [
                            { path: 'full', component: DashboardFull },
                            { path: 'mini', component: DashboardMini },
                            { path: 'map', component: DashboardMap }
                        ]
                    },
                    {
                        path: 'settings',
                        component: () => import('@/Main/Settings'),
                        meta: { requiresAuth: true }
                    },
                    { path: 'legalCompliance', component: LegalCompliance },
                    { path: 'scheduledReports', component: () => import('../App/ScheduledReport/ScheduledReportsList') },
                    {
                        path: 'scheduledReports/add',
                        component: () => import('../App/ScheduledReport/ScheduledReportAdd'),
                        meta:
                        {
                            topReturnTo: '/app/scheduledReports'
                        }
                    },
                    {
                        path: 'scheduledReports/edit/:id',
                        component: () => import('../App/ScheduledReport/ScheduledReportAdd'),
                        meta:
                        {
                            topType: AndroidLikeTopbar.TOPTYPE_INCONTEXT,
                            topTitle: 'Edit schedule',
                            topReturnTo: '/app/scheduledReports'
                        },
                        props: true
                    },
                    {
                        path: 'instantReport',
                        component: () => import('@/App/ScheduledReport/InstantReport'),
                        meta: { requiresAuth: true },
                        children:
                        [
                            { path: '', component: () => import('@/App/ScheduledReport/DeviceList') },
                            { name: 'report', path: ':id', component: () => import('@/App/ScheduledReport/ReportWizard'), props: true }
                        ]
                    },
                    {
                        path: 'admin',
                        component: () => import('../views/app/admin/Admin'),
                        meta: { requiresAuth: true },
                        children:
                        [
                            { path: 'users', component: () => import('../views/app/admin/Users') },
                            { path: 'groups', component: () => import('../views/app/admin/Groups.vue') },
                            { path: 'devices', component: () => import('../views/app/admin/Devices') },
                        ]
                    }
                ],
                meta: { requiresAuth: true }
            },
            {
                path: '/splash',
                component: Splash
            },
            {
                path: '/',
                component: Splash
            },
            {
                path: '/login',
                component: () => import('@/components/Login')
            },
            {
                path: '/logout',
                component: () => import('@/components/Logout')
            },
            {
                path: '/activate/:actKey',
                component: () => import('@/views/Activate'),
                props: true
            },
            {
                path: '/startEnroll',
                component: () => import('@/views/StartEnroll')
            },
            {
                path: '/passwordReset',
                component: () => import('@/views/PasswordReset')
            },
            {
                path: '/paper/sgdatalogger',
                component: () => import('../components/SGDataLoggerMobileCraneReport'),
                meta: { requiresAuth: true }
            },
            {
                path: '/qra/:id',
                component: QRA,
                props: true
            },
        ],
        scrollBehavior(to, from, savedPosition)
        {
            if(savedPosition && to.fullPath !== '/app/dashboard/full')
                return savedPosition
            else
                return { x: 0, y: 0 }
        }
    }
);
