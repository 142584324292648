import moment from 'moment';

export default
{
    lastPing()
    {
        return moment.duration(this.$state.now - this.ping.received);
    },
    pingWarn()
    {
        if(this.ping === null)
            return null;
        if(this.lastPing.asHours() > 1)
            return this.$options.PING_DANGER;
        if(this.lastPing.asMinutes() >= 10)
            return this.$options.PING_WARNING;

        return this.$options.PING_OK;
    },
}