<template>
  
  <div>No device selected</div>
  
</template>

<script>

import store2 from '@/lib/store2';

export default
{
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    mounted()
    {
        console.log('nodeviceselected');
        if(this.$state.defaultDeviceId === '')
        {
            this.$apollo.provider.defaultClient.query(
                {
                    query: require('../gql/DeviceSelector.gql')
                }
            ).then(({data}) => this.$state.defaultDeviceId = data.devices.nodes[0].id
            ).then(this.forward);
        }
        else
        {
            this.forward();
        }
    },
    methods:
    {
        async forward()
        {
            // TODO: Eliminate rootPath

            if(this.rootPath)
                await this.$router.replace(`${this.rootPath}/${this.$state.defaultDeviceId}`);
            else
                await this.$router.replace(`${this.$route.path}/${this.$state.defaultDeviceId}`);
        }
    },
    props:
    {
        rootPath: { type: String, required: false }
    }
}
</script>

<style>
</style>