<template>
    <b-container>
      <b-row>
        <b-col class="mt-5">
          <template v-if="error">
            <b-alert show variant="danger">{{error}}</b-alert>
          </template>
          <p v-else class="text-center">
            Counting backwards from infinity...
          </p>
        </b-col>
      </b-row>
    </b-container>
</template>

<script>

import isArray from 'lodash/isArray';

export default
{
    data()
    {
        return { error: '' }
    },
    mounted()
    {
        try
        {
            const decoded = JSON.parse(atob(this.id));

            if(!isArray(decoded))
            {
                this.error = 'Incorrect datatype';
                return;
            }

            if(decoded.length !== 2)
            {
                this.error = 'Malformed';
                return;
            }

            const [objType] = decoded;

            switch(objType)
            {
                case 'oil_samples':
                    return this.$router.replace(`/app/oilSamples/${this.id}`).catch(() => {});
                default:
                    this.error = 'Unknown object type';
                    return;
            }
        }
        catch (e)
        {
            this.error = 'Failed to decode ID';
            console.error(e);
        }
    },
    props:
    {
        id: { type: String }
    }
}
</script>

<style>
</style>