<template>
  <div>
    <div class="map-legend" v-show="showLegend">
      <img src="/img/map_pin_green.svg" alt="Motor on pin"> Motor on<br>
      <img src="/img/map_pin_blue.svg" alt="Online pin"> Online<br>
      <img src="/img/map_pin_gray.svg" alt="Offline pin"> Offline<br>
    </div>
  <GmapMap
    :center="{lat:0, lng:0}"
    :zoom="2"
    map-type-id="satellite"
    style="width: 100%; height: calc(100vh - 150px)"
    @tilesloaded="tilesloaded"
  >
    <GmapMarker
      :clickable="true"
      :draggable="false"
      :icon="m.icon"
      :key="index"
      :options="$options.markerOptions"
      :position="m.position"
      :title="m.title"
      :zIndex="m.zIndex"
      @click="toggleInfoWin(index)"
      v-for="(m, index) in markers"
    >
      <gmap-info-window :opened="!!infoWinOpen[index]" @closeclick="toggleInfoWin(index)">
        <map-crane-card :id="m.id" v-if="!!infoWinOpen[index]" class="border-0"/>
      </gmap-info-window>
    </GmapMarker>
  </GmapMap>
  </div>

</template>

<script>
import Vue             from 'vue';
import MapCraneCard   from '../../../components/MapCraneCard.vue';
import {NETWORK_ONLY} from '../../../lib/constants';

export default
{
    apollo:
    {
        devices:
        {
            query: require('../../../gql/GlobeView.gql'),
            fetchPolicy: NETWORK_ONLY,
            async result()
            {
                this.dataLoadedResolve();
            }
        }
    },
    components: { MapCraneCard },
    computed:
    {
        markers()
        {
            const nodes = this.devices.nodes, len = nodes.length, result = new Array(len), pingValid = Date.now() - 240000;
            const scaledSize = { width: 24, height: 32 };
            let device, motorOn, ping, url, isOnline, zIndex;

            for(let i = 0; i < len; ++i)
            {
                device    = nodes[i];
                ping      = device.ping;
                url       = '/img/map_pin_gray.svg';
                isOnline  = ping && ping.received > pingValid;
                motorOn   = device.motorOn[0] && device.motorOn[0].value === '1';
                zIndex    = 10;

                if(isOnline)
                {
                    url     = '/img/map_pin_blue.svg';
                    zIndex  = 20;
                }

                if(isOnline && motorOn)
                {
                    url     = '/img/map_pin_green.svg';
                    zIndex  = 30;
                }

                result[i] =
                    {
                        id: device.id,
                        icon: { url, scaledSize },
                        position: device.location,
                        title: device.displayName,
                        zIndex
                    };
            }

            return result;
        }
    },
    data()
    {
        let dataLoadedResolve;
        const dataLoaded = new Promise(resolve => dataLoadedResolve = resolve);

        return {
            devices: {nodes: []},
            infoWinOpen: {},
            showLegend: false,
            dataLoaded,
            dataLoadedResolve,
            isInitial: true
        }
    },
    methods:
    {
        toggleInfoWin(idx)
        {
            const isOpen = this.infoWinOpen[idx];
            Object.keys(this.infoWinOpen)
                .forEach(key => Vue.set(this.infoWinOpen, key, false));

            Vue.set(this.infoWinOpen, idx, !isOpen);
        },
        async tilesloaded()
        {
            this.showLegend = true;

            if(!this.isInitial || !this.$route.query.focus)
                return;

            this.isInitial = false;
            const focusId = this.$route.query.focus;

            await this.dataLoaded;

            for (let i = 0, len = this.markers.length; i < len; ++i)
            {
                if(this.markers[i].id === focusId)
                {
                    this.toggleInfoWin(i);
                    break;
                }
            }
        }
    },
    markerOptions: { optimized: false }
}
</script>

<style lang="scss">
div.map-legend
{
    position: absolute;
    bottom: 2.1em;
    left: 24px;
    background-color: white;
    opacity: 0.7;
    color: black;
    z-index: 1;
    padding: .3rem;
    img
    {
        &:not(:last-of-type)
        {
            padding-bottom: .25rem;
        }
        width: 16px;
    }
}
</style>