import { createClient } from 'graphql-ws';

export default function(options)
{
    let restartRequested = false;
    let restart = () => { restartRequested = true; };

    const client = createClient(
        {
            ...options,
            on:
            {
                ...options.on,
                opened: (socket) =>
                {
                    options.on && options.on.opened && options.on.opened(socket);

                    restart = () =>
                    {
                        if (socket.readyState === WebSocket.OPEN)
                        {
                            // if the socket is still open for the restart, do the restart
                            socket.close(4205, 'Client Restart');
                        }
                        else
                        {
                            // otherwise the socket might've closed, indicate that you want
                            // a restart on the next opened event
                            restartRequested = true;
                        }
                    };

                    // just in case you were eager to restart
                    if (restartRequested)
                    {
                        restartRequested = false;
                        restart();
                    }
                }
            }
        }
    );

    return { ...client, restart: () => restart() };
}
