<template>
  <div class="panel crane-card">
    <div class="panel-heading">
      <h3 class="display-name"><a href="#" @click.prevent="myDashboards(device)">{{device.displayName}}</a></h3>
    </div>
    <div class="panel-content">
      <p>{{device.customer | emptyStr }}</p>
      <p><i class="fa fa-fw fa-id-card"/> {{device.craneSerial}}</p>
      <p><i class="fa fa-fw fa-tag"/> {{device.series}} series {{device.capacity}} / {{device.lower}}</p>
      <p><i class="fa fa-fw fa-play"/> {{ophours / 3600 | number_format(0, ',', ' ')}} hours</p>

      <template v-if="motorOn">
        <p class="text-success"><i class="fa fa-fw fa-power-off"/> Motor on / {{kgph / 1000 | number_format(0, ',', '')}} tonnes per hour</p>
      </template>
      <template v-else>
        <p><i class="fa fa-fw fa-power-off"/> Motor off</p>
      </template>

      <p class="hand-on-hover"
         v-b-tooltip.click :title="interval.title"
         v-for="interval in maintenance">
          <span v-if="interval.dueIn === undefined" class="text-muted-30"><i class="fa fa-fw fa-wrench"/> {{interval.name}} hr service status unknown</span>
          <span v-else-if="interval.dueIn > 48">
            <i class="fa fa-fw fa-wrench"/> {{interval.name}} hr service due in {{interval.dueIn}} hr <small>± {{interval.suggest}}</small>
          </span>
          <span v-else-if="interval.dueIn <= 48 && interval.dueIn > 0" class="text-warning">
            <i class="fa fa-fw fa-wrench"/> {{interval.name}} hr service due in {{interval.dueIn}} hr <small>± {{interval.suggest}}</small>
          </span>
          <span v-else-if="interval.dueIn <= 0" class="text-danger">
            <i class="fa fa-fw fa-wrench"/> {{interval.name}} hr service overdue
          </span>
      </p>

      <p
         v-bind:class="{ 'text-success': pingWarn === $options.PING_OK, 'text-warning': pingWarn === $options.PING_WARNING, 'text-danger': pingWarn === $options.PING_DANGER, 'hand-on-hover': $can('viewSoftwareStatus', device) }"
         @click="$can('viewSoftwareStatus', device) && $emit('pingClicked', id)"
      >
        <template v-if="ping === null"><i class="fa fa-fw fa-rss"/> Unknown</template>
        <template v-else><i class="fa fa-fw fa-rss"/> {{lastPing.humanize()}} ago</template>
      </p>
      <div class="text-center">

        <div class="btn-group mb-2 mt-2">
          <button
            class="btn btn-light" type="button"
            v-for="(option, id) in $options.timeIntervals"
            :class="{ active: timeIntervalId === id }"
            :value="id"
            @click="changeTimeInterval" >{{option.shortDisplayName}}</button>
        </div>

        <div class="pb-2 mb-2 chart-container">

            <div class="d-flex align-items-center">
                <button class="btn btn-link mr-auto" @click="goToCycles(device)">{{$options.chartTypes[charts[0].type].title}}</button>
                <b-dropdown text="Change" variant="link" right class="switch-type-dropdown">
                  <b-dropdown-item v-for="(type, idx) in $options.chartTypes" @click="changeChartType(0, idx)" v-if="type.selectable" :key="`${deviceId}_c_${idx}`" link-class="switch-type-item">
                    <b-form-radio :checked="charts[0].type" :value="idx">{{type.title}}</b-form-radio>
                  </b-dropdown-item>
                </b-dropdown>
            </div>

          <div class="loader-background" v-show="charts[0].working > 0">
            <div class="loader"><small-loader/></div>
          </div>

          <chart-js :data="chart1" :options="chart1Options" :height="135" type="bar" />

        </div>

        <div class="pb-2 mb-2 chart-container">

          <p><button class="btn btn-link" @click="goToAlarms(device)">Alarms triggered</button></p>

          <div class="loader-background" v-show="charts[1].working > 0">
            <div class="loader"><small-loader/></div>
          </div>

          <chart-js :data="chart2" :options="$options.miniGraphOptions" :height="135" type="bar" />
        </div>
        <div class="d-flex justify-content-between">
          <b-btn variant="link" :to="`/app/dashboard/map?focus=${device.id}`">Map</b-btn>
          <button @click="myDashboards(device)" class="btn btn-link">My dashboards</button>
          <a :href="`https://secure.logmein.com/mycomputers_connect.asp?hostid=${device.lmiId}`" target="_blank" class="btn btn-link" v-if="$can('connectLogmein', device) && device.lmiId">Logmein <i class="fa fa-external-link"></i></a>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import get from 'lodash/get';

import SmallLoader                          from '../components/SmallLoader';
import {CACHE_AND_NETWORK, NETWORK_ONLY}    from '../lib/constants';
import craneCardCommon                      from '../lib/craneCardCommon';
import store2                               from '@/lib/store2';
import ChartJs                              from '@/components/ChartJs.vue';


export default
{
    beforeDestroy()
    {
        this.abort.abort();
    },
    apollo:
    {
        $subscribe:
        {
            telemData:
            {
                query: require('../gql/live/cranecard.gql'),
                variables() { return { id: this.id } },
                result({data: { deviceById }})
                {
                    this.ping       = deviceById.ping;
                    this.motorOn    = JSON.parse(get(deviceById.motorOn, '[0].value', '0'));
                    this.kgph       = JSON.parse(get(deviceById.kgph, '[0].value', '0'));
                    this.ophours    = JSON.parse(get(deviceById.ophours, '[0].value', '0'));
                    this.maintenances = deviceById.maintenances.nodes;
                },
            }
        },
        device:
        {
            query: require('../gql/CraneCard.gql'),
            variables() { return { id: this.id } },
            fetchPolicy: CACHE_AND_NETWORK
        }
    },
    components: { ChartJs, SmallLoader },
    props:
    {
        deviceId:       { type: Number, required: true },
        id:             { type: [Number, String], required: true }
    },
    computed:
    {
        $state() { return store2(this.$pinia) },
        maintenance()
        {
            return this.$options.maintIntervals.map(
                (name, idx) =>
                {
                    const data = this.maintenances[idx];
                    const title = data ? `${name} hr last performed at ${data.lastPerformed} operating hours` : '';

                    return { name, title, ...data };
                }
            );
        },
        timeInterval()
        {
            return this.$options.timeIntervals[this.timeIntervalId];
        },
        timeIntervalId()
        {
            return this.$state.craneCardInterval(this.id, '8h');
        },
        chart2()
        {
            return {
                labels: this.charts[1].x,
                datasets:
                [
                    {
                        barPercentage: 0.7,
                        backgroundColor: '#9b59b6',
                        data: this.charts[1].y,
                        type: this.charts[1].traceType
                    }
                ]
            };
        },
        chart1()
        {
            return {
                labels: this.charts[0].x,
                datasets:
                [
                    {
                        barPercentage: 0.7,
                        backgroundColor: '#26B99A',
                        borderColor: '#26B99ADD',
                        data: this.charts[0].y,
                        type: this.charts[0].traceType
                    }
                ]
            };
        },
        lastPing: craneCardCommon.lastPing,
        pingWarn: craneCardCommon.pingWarn,
        chart1Options()
        {
            return this.$options.chartTypes[this.charts[0].type].options.bind(this)(this.timeInterval)
        }
    },
    created()
    {
        this.$state.craneCardChartType(this.id, []).forEach(
            (type, idx) => this.charts[idx].type = type || this.charts[idx].type
        );
    },
    data()
    {
        return {
            abort: new AbortController(),
            charts:
            [
                { working: 0, x: [], y: [], type: 0, traceType: 'bar' },
                { working: 0, x: [], y: [], type: 3, traceType: 'bar' }
            ],
            range: [],
            device: {},
            ping: null,
            motorOn: null,
            kgph: 0,
            maintenances: [],
            ophours: 0
        }
    },
    emits: ['pingClicked'],
    methods:
    {
        async changeChartType(idx, type)
        {
            this.charts[idx].type = type;
            this.$state.setCraneCardChartType(this.id, idx, type);
            return this.updateChart(idx, true);
        },
        changeTimeInterval(ev)
        {
            this.$state.setCraneCardInterval(this.id, ev.target.value);
        },
        goToCycles(device)
        {
            this.$state.dtChangeFromWidget({ start: this.range[0], end: this.range[1] });
            this.$state.defaultDeviceId = device.id;
            this.$router.push(`/app/cycles/device/${this.$state.defaultDeviceId}`);
        },
        goToAlarms(device)
        {
            this.$state.dtChangeFromWidget({ start: this.range[0], end: this.range[1] });
            this.$state.defaultDeviceId = device.id;
            this.$router.push(`/app/alarms/device/${this.$state.defaultDeviceId}`);
        },
        myDashboards(device)
        {
            this.$state.defaultDeviceId = device.id;
            this.$router.push(`/app/deviceDetail/${this.$state.defaultDeviceId}`);
        },
        async updateChart(idx, loadAnim)
        {
            const chart = this.charts[idx];
            chart.working = loadAnim ? 1 : 0;

            const type = this.$options.chartTypes[chart.type];

            try
            {
                const { data: {device: { metricsOverTime }} } = await this.$apollo.query(
                    {
                        query: type.query,
                        fetchPolicy: NETWORK_ONLY,
                        variables:
                        {
                            id: this.deviceId,
                            from: this.timeInterval.from,
                            to: 'now',
                            timestampFormat: this.timeInterval.sqlDateFmt,
                            by: this.timeInterval.sqlTrunc
                        },
                        context:
                        {
                            fetchOptions: { signal: this.abort.signal }
                        }
                    }
                );

                chart.x = metricsOverTime.timestamps;
                chart.y = metricsOverTime.chart.y;
                chart.traceType = type.traceType;
                this.range[0] = metricsOverTime.start;
                this.range[1] = metricsOverTime.end;
            }
            catch (e)
            {
                console.error(e);
            }
            finally
            {
                chart.working = 0;
            }
        }
    },
    watch:
    {
        '$state.now'()
        {
            this.charts.forEach((chart, idx) => this.updateChart(idx, false));
        },
        timeInterval()
        {
            this.charts.forEach((chart, idx) => this.updateChart(idx, false));
        }
    },
    mounted()
    {
        this.$nextTick(
            () =>
            {
                this.charts.forEach((chart, idx) => this.updateChart(idx, true));
            }
        );
    },
    timeIntervals:
    {
        '8h': { shortDisplayName: '8 H', from: 'now-7h/h', sqlTrunc: 'HOUR', sqlDateFmt: 'Dy HH24:00', maxY: 1 },
        '7d': { shortDisplayName: '7 D', from: 'now-6d/d', sqlTrunc: 'DAY', sqlDateFmt: 'Dy', maxY: 24 },
        '4w': { shortDisplayName: '4 W', from: 'now-3w/w', sqlTrunc: 'WEEK', sqlDateFmt: '"Week" IW', maxY: 168 },
        '6M': { shortDisplayName: '6 M', from: 'now-5M/M', sqlTrunc: 'MONTH', sqlDateFmt: 'FMMonth', maxY: 744 }
    },
    miniGraphOptions:
    {
        responsive: true,
        maintainAspectRatio: false,
        plugins:
        {
            legend: { display: false }
        },
        scales:
        {
            x:
            {
                grid: { borderColor: '#acacac'},
                afterFit: scale => scale.height = 55,
                ticks: { padding: -4 }
            },
            y:
            {
                type: 'linear',
                beginAtZero: true,
                grid: { borderColor: '#acacac'},
                ticks: { callback(val) { return Number.isInteger(val) ? val : undefined; } },
                afterFit: scale => scale.width = 50
            }
        },
        layout: { padding: { left: 0, right: 3 } }
    },
    PING_OK: 0,
    PING_WARNING: 1,
    PING_DANGER: 2,
    maintIntervals: [250, 500, 1000, 2000],
    chartTypes:
    [
        {
            options() { return this.$options.miniGraphOptions },
            query: require('../gql/query/craneCardTonnes.gql'),
            selectable: true,
            title: 'Tonnes handled',
            traceType: 'bar',
        },
        {
            options(interval)
            {
                const options       = {...this.$options.miniGraphOptions};
                options.scales      = {...options.scales};
                options.scales.y    = {...options.scales.y, min: 0, max: interval.maxY};
                return options;
            },
            query: require('../gql/query/craneCardHours.gql'),
            selectable: true,
            title: 'Motor hours',
            traceType: 'bar'
        },
        {
            options() { return this.$options.miniGraphOptions },
            query: require('../gql/query/craneCardTph.gql'),
            selectable: true,
            title: 'Tonnes per hour (speed)',
            traceType: 'line',
        },
        {
            options() { return this.$options.miniGraphOptions },
            query: require('../gql/query/craneCardAlarms.gql'),
            title: 'Alarms triggered',
            traceType: 'bar',
        }
    ]
}
</script>

<style>
  .chart-container
  {
    border-radius: 3px;
    border: 1px solid rgb(230, 233, 237);
    box-shadow: 2px 2px 10px 0px rgb(220,220,220);
    position: relative;
  }
  .crane-card p
  {
      margin-bottom: 0;
      text-align: left;
  }
  .display-name
  {
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
  }
  .loader
  {
    position: absolute;
    top: 50%;
    left: 50%
  }
  .loader-background
  {
    position: absolute;
    background-color: rgba(0, 0, 0, .15);
    height: 100%;
    width: 100%;
    top: 0
  }
  .switch-type-dropdown > button,
  .switch-type-dropdown > button:focus
  {
      box-shadow: none;
      text-decoration: none !important;
  }
  .switch-type-item > div > label
  {
      cursor: pointer !important;
  }
</style>