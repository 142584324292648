<template>
  <div>
    <div class="d-flex align-items-center mb-2 pl-2">
        <b-pagination v-model="page" :total-rows="totalCount" :per-page="perPage" class="mb-0" size="sm"/>
        <div style="width: 100%; text-align: center">{{list.length}} of {{totalCount}}</div>
        <div class="ml-auto text-nowrap"><b-btn variant="link" @click="clearSearch">Reset filter</b-btn></div>
    </div>
    <slot name="top"></slot>
    <b-table
      :class="loading ? 'busy' : ''"
      :fields="fields"
      :items="list"
      :sort-by.sync="tableSortBy"
      :sort-desc.sync="tableSortDesc"
      @sort-changed="sortChanged"
      class="valign-middle"
      v-bind="$attrs"
      v-on="$listeners"
      no-local-sorting
      primary-key="id"
      ref="innerTable"
      v-touch:swipe="swipeHandler"
      v-touch:touchhold="touchholdHandler"
      v-touch:tap="tapHandler"
    >
      <slot v-for="slot in Object.keys($slots)" :name="slot" :slot="slot"/>
      <template v-for="slot in Object.keys($scopedSlots)" :slot="slot" slot-scope="scope">
        <slot :name="slot" v-bind="scope"/>
      </template>

      <template #top-row>
        <b-td v-for="field in fields" :key="`search_${field.key}`" :class="field.searchTdClass">
          <template v-if="field.search === $options.SEARCH_STRING">
            <b-input v-model="search[field.key]"/>
          </template>
          <template v-if="field.search === $options.SEARCH_ENUM">
            <table-wrap-enum-dropdown v-model="search[field.key]" :options="field.values" />
          </template>
          <template v-if="field.search === $options.SEARCH_BOOLEAN">
            <table-wrap-bool-dropdown v-model="search[field.key]" />
          </template>
          <template v-if="field.selectAll">
            <b-checkbox @change="selectAllChange" v-model="selectAllValue" />
          </template>
        </b-td>
      </template>
    </b-table>
  </div>
  
</template>

<script>
import get                   from 'lodash/get';
import set                   from 'lodash/set';
import identity              from 'lodash/identity';
import gql_sort_name         from '@/lib/gql_sort_name';
import TableWrapEnumDropdown from '@/components/TableWrapEnumDropdown';
import TableWrapBoolDropdown from '@/components/TableWrapBoolDropdown.vue';

export default
{
    apollo:
    {
        $subscribe:
        {
            items:
            {
                debounce: 50,
                query() { return this.query },
                result({data})
                {
                    let f, list;

                    if(this.listPath)
                        f = get(data, this.listPath, '');
                    else
                        f = Object.values(data)[0];

                    if(Array.isArray(f))
                        list = Object.freeze(f);
                    else
                        list = Object.freeze(f.nodes);

                    this.totalCount = f.totalCount;
                    this.list       = list;
                    this.loading    = false;
                    this.$nextTick(() => this.$emit('dataLoaded', list));
                },
                variables()
                {
                    this.loading = true;

                    return {
                        ...this.variables,
                        first: this.perPage,
                        offset: (this.page - 1) * this.perPage,
                        filter: this.gqlFilter(),
                        orderBy: this.orderBy
                    }
                }
            }
        }
    },
    beforeDestroy()
    {
        this.$emit(
            'update:state',
            {
                list:           this.list,
                orderBy:        this.orderBy,
                page:           this.page,
                search:         this.search,
                tableSortBy:    this.tableSortBy,
                tableSortDesc:  this.tableSortDesc,
                totalCount:     this.totalCount
            }
        );
    },
    components: { TableWrapBoolDropdown, TableWrapEnumDropdown },
    computed:
    {
        selectable()
        {
            return this.selectCount > 0;
        }
    },
    data()
    {
        let
            defaultOrderBy         = 'NATURAL',
            defaultTableSortBy     = '',
            defaultTableSortDesc   = null,
            list                   = [];

        const firstSort = this.fields.find(f => f.initialSortDesc !== null && f.initialSortDesc !== undefined);

        if(firstSort !== undefined)
        {
            defaultOrderBy        = gql_sort_name(firstSort.sort, firstSort.initialSortDesc);
            defaultTableSortBy    = firstSort.key;
            defaultTableSortDesc  = firstSort.initialSortDesc
        }

        const
            defaultPage           = 1,
            defaultSearch         = this.initialSearch();

        const
            orderBy       = get(this.state, 'orderBy', defaultOrderBy),
            page          = get(this.state, 'page', defaultPage),
            search        = get(this.state, 'search', defaultSearch),
            tableSortBy   = get(this.state, 'tableSortBy', defaultTableSortBy),
            tableSortDesc = get(this.state, 'tableSortDesc', defaultTableSortDesc),
            totalCount    = get(this.state, 'totalCount', 0)
            list          = get(this.state, 'list', list);

        return {
            page,
            list,
            search,
            selectAllValue: false,
            totalCount,
            orderBy,
            tableSortBy,
            tableSortDesc,
            defaultOrderBy,
            defaultTableSortBy,
            defaultTableSortDesc,
            loading: true,
            selectCount: 0
        }
    },
    emits: ['dataLoaded'],
    methods:
    {
        setSearchForField(key, value)
        {
            this.$set(this.search, key, value);
        },
        clearSearch()
        {
            this.$vueSet(this, 'search', this.initialSearch());
            this.orderBy        = this.defaultOrderBy;
            this.tableSortBy    = this.defaultTableSortBy;
            this.tableSortDesc  = this.defaultTableSortDesc;
            this.page           = 1;
        },
        clearSelected()
        {
            this.selectAllValue = false;
            this.selectCount    = 0;
            this.$refs.innerTable.clearSelected();
        },
        initialSearch()
        {
            const search = {};

            let field;

            for (field of this.fields)
            {
                switch(field.search)
                {
                    case this.$options.SEARCH_BOOLEAN:
                        search[field.key] = undefined;
                        break;
                    case this.$options.SEARCH_STRING:
                        search[field.key] = '';
                        break;
                    case this.$options.SEARCH_ENUM:
                        search[field.key] = field.values;
                        break;
                }
            }

            return search;
        },
        gqlFilter()
        {
            const filter = {};

            let field, key, path, searchFor;

            for (field of this.fields)
            {
                key       = field.key;
                path      = field.searchPath || key;
                searchFor = this.search[key];

                if(searchFor === undefined)
                    continue;

                switch (field.search)
                {
                    case this.$options.SEARCH_STRING:
                        set(filter, path, searchFor === '' ? {} : {likeInsensitive: `%${searchFor}%`});
                        break;

                    case this.$options.SEARCH_ENUM:
                        set(filter, path, {in: searchFor});
                        break;

                    case this.$options.SEARCH_BOOLEAN:
                        switch(searchFor)
                        {
                            case true: set(filter, path, {equalTo: true}); break;
                            case false: set(filter, path, {equalTo: false}); break;
                            case undefined: set(filter, path, undefined); break;
                        }
                        break;
                }
            }

            return this.filterHook(filter);
        },
        selectAllChange(ev)
        {
            if(ev)
                this.$refs.innerTable.selectAllRows();
            else
                this.$refs.innerTable.clearSelected();
        },
        sortChanged({sortBy, sortDesc})
        {
            if(sortBy === '')
            {
                // TODO: This happens when user clicks on unsortable column
            }
            else
            {
                const field   = this.fields.find(f => f.key === sortBy);
                this.orderBy  = gql_sort_name(field.sort, sortDesc);
                this.page     = 1;
            }
        },
        swipeHandler(direction)
        {
            /*if(direction === 'left')
            {
                const totalPages = Math.ceil(this.totalCount / this.perPage);
                if(totalPages !== this.page)
                    this.page++;
            }
            else if(direction === 'right')
            {
                if(this.page > 1)
                    this.page--;
            }*/
        },
        selectRow(index) { return this.$refs.innerTable.selectRow(index) },
        touchholdHandler(event)
        {
            let parent = event.target.parentElement;

            while(parent.rowIndex === undefined)
                parent = parent.parentElement;

            const rowIdx = parent.rowIndex - 2;

            this.toggleSelect(rowIdx);

            if(this.selectCount === 0 && navigator.vibrate)
                navigator.vibrate(20);
        },
        tapHandler(event)
        {
            let parent = event.target.parentElement;

            while(parent.rowIndex === undefined)
                parent = parent.parentElement;

            const rowIdx = parent.rowIndex - 2;

            if(this.selectCount === 0)
                return this.$emit('singleTap', this.list[rowIdx]);
            else
                this.toggleSelect(rowIdx);
        },
        rowClicked(row, rowIdx)
        {
            if(this.selectCount === 0)
                return;

            this.toggleSelect(rowIdx);
        },
        toggleSelect(rowIdx)
        {
            if(this.$refs.innerTable.isRowSelected(rowIdx))
            {
                this.$refs.innerTable.unselectRow(rowIdx);
                this.selectCount--;
            }
            else
            {
                this.$refs.innerTable.selectRow(rowIdx);
                this.selectCount++;
            }
        }
    },
    props:
    {
        query:      {},
        state:      { type: Object, default() { return {} } },
        fields:     { type: Array, default() { return [] } },
        perPage:    { type: Number, required: false, default() { return 20; } },
        listPath:   { type: String },
        variables:  { type: Object, default() { return {}} },
        filterHook: { type: Function, default: identity }
    },
    SEARCH_BOOLEAN: 'Boolean',
    SEARCH_ENUM: 'ENUM',
    SEARCH_STRING: 'String'
}
</script>

<style>
table.busy thead tr th
{
    border-bottom: #ffc107 2px solid !important;
}
</style>