export const ACTIVATION_SOURCE_RESET_PASSWORD = 'RESET_PASSWORD';
export const CACHE_FIRST        = 'cache-first';
export const CACHE_AND_NETWORK  = 'cache-and-network';
export const NETWORK_ONLY       = 'network-only';
export const PLOTLY_CLICK_ANNOTATION    = 'plotly_clickannotation';
export const PLOTLY_RELAYOUT            = 'plotly_relayout';
export const PLOTLY_DOUBLECLICK         = 'plotly_doubleclick';
export const SHAPE_TYPE_LINE            = 'line';
export const REF_X                      = 'x';
export const REF_Y                      = 'y';
export const REF_PAPER                  = 'paper';
export const TRACE_FILL_NONE            = 'none';
export const TRACE_FILL_TONEXTY         = 'tonexty';
export const TRACE_FILL_TOZEROX         = 'tozerox';
export const TRACE_FILL_TOZEROY         = 'tozeroy';
export const SCATTER_MODE_LINES         = 'lines';
export const SCATTER_MODE_MARKERS       = 'markers';
export const SCATTER_MODE_NONE          = 'none';
export const SCATTER_MODE_TEXT          = 'text';
export const EVENT_ARRAY_PUSH           = 'arrayPush';
export const BEST_FIT                   = 'bestfit';
export const EVENT_INPUT                = 'input';
export const BOOL_STYLE_ONOFF           = 'onoff';
export const BOOL_STYLE_10              = '10';
export const BOOL_STYLE_HL              = 'hl';
export const BOOL_STYLE_SMILEY          = 'smiley';
export const BOOL_STYLE_CUSTOM          = 'custom';
export const SORT_ASC                   = 'asc';
export const RELATIVE_TIME_RE           = /^now(\/[yMwdhm]+)?([+-]([0-9]+)([yMwdhms]))?(\/[yMwdhm])?$/;
export const TIME_INTERVAL_RE           = /^([0-9]+)([yMwdhms])$/;
export const HTTP_UNAUTHORIZED          = 401;
export const ENV_VUE_APP_REST_HTTP      = 'VUE_APP_REST_HTTP';
export const ENV_VUE_APP_GRAPHQL_HTTP   = 'VUE_APP_GRAPHQL_HTTP';

// Local Storage
export const LS_OPENMENUSECTIONS        = 'openMenuSections';