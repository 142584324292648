<template>

  <b-dropdown :html="html" variant="outline-secondary" block :key="instanceId">
      <b-dropdown-item @click.capture="val = true">
        <div class="d-flex align-items-center">
          <input type="radio" :checked="val === true" class="mr-1"/> Yes
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click.capture="val = false">
        <div class="d-flex align-items-center">
          <input type="radio" :checked="val === false" class="mr-1"/> No
        </div>
      </b-dropdown-item>
      <b-dropdown-item @click.capture="val = undefined">
        <div class="d-flex align-items-center">
          <input type="radio" :checked="val === undefined" class="mr-1"/> Either
        </div>
      </b-dropdown-item>
  </b-dropdown>

</template>

<script>
import uniqueId from 'lodash/uniqueId';

export default
{
    computed:
    {
        val:
        {
            get() { return this.value; },
            set(v)
            {
                this.instanceId = uniqueId('v_');
                this.$emit('input', v);
            }
        },
        html()
        {
            switch (this.value)
            {
                case true: return 'Y';
                case false: return 'N';
                case undefined: return '<i>Y&N</i>'
                default: return '<i>Y&N</i>'
            }
        }
    },
    data()
    {
        return {
            instanceId: uniqueId('v_')
        }
    },
    props:
    {
        value: { }
    }
}
</script>

<style>
</style>