import isString from 'lodash/isString';

/**
 *
 * @param {string} currentPath
 * @param {string} newPath
 */
export default function interpretPath(currentPath, newPath)
{
    if(!isString(newPath))
        return newPath;

    if(newPath === '..')
    {
        const splitted = currentPath.split('/');
        splitted.pop();
        return splitted.join('/');
    }
    else
    {
        return newPath;
    }
}