<template>
  <div>
    <b-breadcrumb class="d-none d-sm-flex">
      <b-breadcrumb-item>Service</b-breadcrumb-item>
      <b-breadcrumb-item to="/app/oilSamples">Oil samples</b-breadcrumb-item>
      <b-breadcrumb-item active>Request</b-breadcrumb-item>
    </b-breadcrumb>
      <b-container fluid>
        <b-row align-h="center">
          <b-col md="12" lg="7" xl="5">
            <div class="panel normative">
              <div class="panel-heading">
                <h3>Oil analysis request</h3>
              </div>
              <div class="panel-content">
                <b-form>
                  <b-form-group label="Origin">
                    <b-radio-group :options="$options.originOptions" stacked v-model="fields.origin.value" />
                  </b-form-group>
                  <b-form-group label="E-Crane" :state="fields.device.state">
                    <b-select :state="fields.device.state" v-model="fields.device.value" @change="deviceChanged" required>
                      <b-select-option v-for="dev in deviceOptions" :value="dev.id" :key="dev.id">{{dev.displayName}} ({{dev.project}})</b-select-option>
                      <template v-slot:first><option disabled value="">-- Select an option --</option></template>
                    </b-select>
                  </b-form-group>
                  <b-form-group label="Oil" :state="fields.oil.state" class="form-group-d-flex">
                    <template v-if="withNewOil">
                      <b-input required placeholder="Name of the oil" autofocus v-model="fields.newOilName.value" :state="fields.newOilName.state" />
                      <b-btn class="ml-2" variant="outline-warning" @click="() => {fields.oil.value = null; withNewOil = false;}"><i class="fa fa-fw fa-times"></i></b-btn>
                    </template>
                    <template v-else>
                      <b-select :options="oilOptions" v-model="fields.oil.value" :state="fields.oil.state" @change="oilChanged">
                        <template v-slot:first>
                          <option disabled :value="null">-- Select an option --</option>
                          <option value="new" style="font-style: italic;">&nbsp;Other</option>
                        </template>
                      </b-select>
                    </template>
                  </b-form-group>
                  <b-form-group label="Sample date">
                    <b-form-datepicker v-model="sampleDateHtml"/>
                  </b-form-group>
                  <b-form-group label="Sampling point" :state="fields.samplingPoint.state" class="form-group-d-flex">
                    <template v-if="withNewSamplingPoint">
                      <b-input required placeholder="Name of the sampling point" autofocus v-model="fields.newSamplingPointName.value" :state="fields.newSamplingPointName.state" />
                      <b-btn class="ml-2" variant="outline-warning" @click="() => {fields.samplingPoint.value = null; withNewSamplingPoint = false;}"><i class="fa fa-fw fa-times"></i></b-btn>
                    </template>
                    <template v-else>
                      <b-select :options="samplingPointOptions" v-model="fields.samplingPoint.value" :state="fields.samplingPoint.state" @change="samplingPointChanged">
                        <template v-slot:first>
                          <option disabled :value="null">-- Select an option --</option>
                          <option value="new" style="font-style: italic;">&nbsp;Other</option>
                        </template>
                      </b-select>
                    </template>
                  </b-form-group>
                  <b-form-group label="Oil change" :state="fields.oilChange.state">
                    <b-form-radio-group v-model="fields.oilChange.value" :options="$options.OilSampleChangeOil" stacked :state="fields.oilChange.state" @change="fields.oilChange.state = null" required />
                  </b-form-group>
                  <b-form-group label="Filter change" :state="fields.filterChange.state">
                    <b-form-radio-group v-model="fields.filterChange.value" :options="$options.OilSampleChangeFilter" stacked :state="fields.filterChange.state" @change="fields.filterChange.state = null" required />
                  </b-form-group>
                  <b-form-group label="Operating time machine" :state="fields.optimeMachine.state">
                    <b-input-group append="Hours">
                      <b-form-input type="number" number :state="fields.optimeMachine.state" v-model="fields.optimeMachine.value" min="0" required />
                    </b-input-group>
                  </b-form-group>
                  <b-form-group label="Operating time oil" :state="fields.optimeOil.state" class="optime-oil-field">
                    <b-input-group append="Hours">
                      <b-input type="number" number :state="fields.optimeOil.state" v-model="fields.optimeOil.value" min="0" required :disabled="optimeOilUnknown" />
                    </b-input-group>
                    <b-checkbox class="ml-2" v-model="optimeOilUnknown">Unknown</b-checkbox>
                  </b-form-group>
                  <b-form-group label="Options" :state="fields.options.state">
                    <b-form-checkbox-group :options="$options.analysisOptions" v-model="fields.options.value" :state="fields.options.state" />
                  </b-form-group>
                  <b-form-group label="Remarks">
                    <b-textarea v-model="fields.requestComment.value" />
                  </b-form-group>
                  <div class="panel-footer-actions">
                    <b-overlay :show="busy" spinner-small class="d-inline-block">
                      <b-dropdown split variant="primary" text="Submit request and print label" @click="submit">
                        <b-dropdown-item @click="submitNoPrint">Submit request, don't print label</b-dropdown-item>
                      </b-dropdown>
                    </b-overlay>
                    <b-btn class="ml-1" to="/app/oilSamples" variant="outline-secondary">Cancel</b-btn>
                  </div>
                </b-form>
              </div>

              <b-modal title="Oil sample reference" v-model="showRef" centered ok-title="I've labeled the sample" ok-only no-close-on-backdrop no-close-on-esc hide-header-close
                       @ok="oilRefOk">
                <div class="text-center">
                  <p class="text-success" v-if="printLabel && printOk">Print job sent to label printer</p>
                  <p class="text-danger" v-if="printLabel && !printOk">Print job failed</p>
                  <h5>Please label the oil sample with:</h5>
                  <p class="text-monospace" style="font-size: 1.5rem">{{sampleRef}}</p>
                </div>
              </b-modal>
            </div>
          </b-col>
        </b-row>
      </b-container>
  </div>

</template>

<script>

import BusyBtn      from '@/components/BusyBtn.vue';
import {DATE_INPUT_FMT} from '@/lib/dateComputed';
import moment                            from 'moment';
import {CACHE_AND_NETWORK, NETWORK_ONLY} from '@/lib/constants';
import stubTrue from 'lodash/stubTrue';
import store2 from '@/lib/store2';
import OilSampleChangeOil from '@/lib/enums/OilSampleChangeOil';
import OilSampleChangeFilter   from '@/lib/enums/OilSampleChangeFilter';
import {ff, ffCollectionToGql} from '@/lib/ff.mjs';

export default
{
    apollo:
    {
        seed:
        {
            query: require('../../../gql/query/requestOilQualitySeed.gql'),
            manual: true,
            result({data})
            {
                this.samplingPointOptions   = data.samplingPoints.nodes;
                this.oilOptions             = data.oils.nodes;
                this.deviceOptions          = data.devices.nodes;
            },
            fetchPolicy: CACHE_AND_NETWORK
        }
    },
    components: { BusyBtn },
    computed:
    {
        $state() { return store2(this.$pinia) },
        sampleDateHtml:
        {
            get()
            {
                return moment(this.fields.sampled.value).format(DATE_INPUT_FMT);
            },
            set(v)
            {
                this.fields.sampled.value = moment(v, DATE_INPUT_FMT).valueOf();
            }
        }
    },
    created()
    {
        this.$state.setTopActions({ click: this.submit, icon: 'print' });
    },
    data()
    {
        return {
            // Seed data
            deviceOptions: [],
            oilOptions: [],
            samplingPointOptions: [],
            busy: false,
            fields:
            { // Validate: true = OK, false = not ok, null = no state
                device:                 ff('', v => v !== ''),
                oil:                    ff(null, v => this.withNewOil ? true : v !== null, v => this.withNewOil ? undefined : v),
                origin:                 ff('INTERNAL', v => v !== undefined),
                sampled:                ff(Date.now()),
                samplingPoint:          ff(null, v => this.withNewSamplingPoint ? true : v !== null, v => this.withNewSamplingPoint ? null : v),
                newSamplingPointName:   ff('', v => this.withNewSamplingPoint ? v !== '' : true),
                newOilName:             ff('', v => this.withNewOil ? v !== '' : true),
                optimeMachine:          ff('', v => v !== ''),
                optimeOil:              ff('', v => this.optimeOilUnknown ? true : v !== '', v => this.optimeOilUnknown ? null : v),
                oilChange:              ff(),
                filterChange:           ff(),
                options:                ff(this.$options.analysisOptions.filter(v => v.default).map(v => v.value), stubTrue),
                requestComment:         ff('', stubTrue)
            },
            withNewSamplingPoint: false,
            withNewOil: false,
            sampleRef: '',
            showRef: false,
            optimeOilUnknown: false,
            printLabel: false,
            printOk: false
        }
    },
    methods:
    {
        samplingPointChanged(e)
        {
            this.fields.samplingPoint.state = null;
            this.fields.newSamplingPointName.state = null;

            if(e !== 'new')
                return;

            this.withNewSamplingPoint   = true;
        },
        oilChanged(e)
        {
            this.fields.oil.state = null;
            this.fields.newOilName.state = null;

            if(e !== 'new')
                return;

            this.withNewOil = true;
        },
        async deviceChanged(id)
        {
            this.fields.device.state = null;

            const { data } = await this.$apollo.query(
                {
                    query: require('../../../gql/query/lastMotorHours.gql'),
                    variables: { id },
                    fetchPolicy: NETWORK_ONLY
                }
            );
            const seconds = data.deviceById.lastsList[0]?.value;

            if(seconds === undefined)
                this.fields.optimeMachine.value = '';
            else
                this.fields.optimeMachine.value = Math.floor(seconds / 3600);
        },
        async submit(evt, printLabel = true)
        {
            this.printLabel = printLabel;
            const input = await ffCollectionToGql(this.fields);

            if(input === null)
                return;

            input.printLabel = printLabel;

            this.busy = true;

            try
            {
                const { data } = await this.$apollo.mutate(
                    {
                        mutation: require('@/gql/mutations/requestOilAnalysis.gql'),
                        variables: { input }
                    }
                );

                this.sampleRef  = data.requestOilAnalysis.oilSample.ourRef;
                this.printOk    = data.requestOilAnalysis.printOk;

                if(this.printLabel && !this.printOk)
                    console.error(data.requestOilAnalysis.printResponse);

                this.showRef    = true;
            }
            catch (e)
            {
                console.error(e);
            }

            this.busy = false;
        },
        async submitNoPrint(evt)
        {
            return this.submit(evt, false);
        },
        oilRefOk()
        {
            return this.$router.replace('/app/oilSamples');
        }
    },
    OilSampleChangeOil,
    OilSampleChangeFilter,
    originOptions:
    [
        { html: 'Internal <small>(handled by E-Crane)</small>', value: 'INTERNAL' },
        { html: 'External <small>(handled by customer)</small>', value: 'EXTERNAL' }
    ],
    analysisOptions:
    [
        { text: 'Particle count', value: 'PARTICLE_COUNT', default: true }
    ]
}
</script>

<style lang="scss">
.optime-oil-field
{
    > div
    {
        display: flex;
        align-items: center;
    }
}
</style>