/**
 * A number, or a string containing a number.
 * @typedef {Object} FormField
 * @property {?*} value
 * @property {?boolean} state
 * @property {function} validate
 * @property {function} toGql
 */
import identity from 'lodash/identity';


/**
 * @param {?*} value
 * @param {function} validate
 * @param {function} toGql
 * @return {FormField}
 */
export function ff(
    value = null,
    validate = v => v !== null,
    toGql = identity
)
{
    return { value, state: null, validate, toGql }
}

export async function ffCollectionToGql(objects)
{
    const validationResult = [];

    for (const field of Object.values(objects))
        validationResult.push(field.state = await field.validate(field.value));

    if(validationResult.some(t => t === false))
        return null;

    const input = {};

    let name, field;

    for(name in objects)
    {
        field = objects[name];
        input[name] = await field.toGql(field.value);
    }

    return input;
}