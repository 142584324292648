import Vue                     from 'vue';
import clamp                   from 'lodash/clamp';
import debounce                from 'lodash/debounce';
import isArray                 from 'lodash/isArray';
import noop                    from 'lodash/noop';
import {defineStore}           from 'pinia';

import localstorage_get        from '@/lib/localstorage_get';
import localstorage_set                    from '@/lib/localstorage_set';
import {LS_OPENMENUSECTIONS, NETWORK_ONLY} from '@/lib/constants';
import {onLogin, onLogout}                 from '@/vue-apollo';
import AndroidLikeTopbar from '@/components/AndroidLikeTopbar.vue';

/**
 *
 * @param {TopActionInput} action
 * @return {TopAction}
 */
function normalizeAction(action)
{
    return {
        click:      () => console.log('topAction click'),
        condition:  () => true,
        icon:       '',
        text:       '',
        ...action
    };
}

const updDashDevs = debounce(
    async function(apollo, state)
    {
        await apollo.mutate(
            {
                mutation: require('../gql/mutations/updateDashboardDevices.gql'),
                variables: { userId: state.userId, dashboardDevices: state.dashboardDevices }
            }
        );
    },
    5000
);

export const LIVE_DATA_CONFIG_TAB_IDX = 'liveDataConfigTabIdx';
export const SOFTWARE_STATUS_TAB_IDX  = 'softwareStatusTabIdx';

export default defineStore(
    '$store2',
    {
        actions:
        {
            async loginExisting(vue)
            {
                const apollo = vue.$apollo.getClient();
                await apollo.clearStore();

                const { data } = await apollo.query(
                    {
                        query: require('../gql/query/loginExisting.gql'),
                        fetchPolicy: NETWORK_ONLY
                    }
                );

                return this.login(vue, data);
            },
            async loginLdap(vue, username, password)
            {
                const apollo = vue.$apollo.getClient();
                await apollo.clearStore();

                const { data } = await apollo.mutate(
                    {
                        mutation: require('../gql/mutations/loginLdap.gql'),
                        variables: { username, password }
                    }
                );

                if(data.loginLdap === null)
                    throw 'Unknown username or password';

                return this.login(vue, data.loginLdap);
            },
            async loginLocal(vue, username, password)
            {
                const apollo = vue.$apollo.getClient();
                await apollo.clearStore();

                const { data } = await apollo.mutate(
                    {
                        mutation: require('../gql/mutations/loginLocal.gql'),
                        variables: { username, password }
                    }
                );

                if(data.loginLocal === null)
                    throw 'Unknown username or password';

                return this.login(vue, data.loginLocal);
            },
            async login(vue, payload)
            {
                await onLogin(vue.$apollo.getClient());

                const user      = payload.query.whoAmI;
                const devices   = payload.query.devices;
                const timezone  = Intl.DateTimeFormat().resolvedOptions().timeZone;

                vue.$ability.update(user.abilities);
                vue.$apolloHeaders.Prefer = `timezone=${timezone}`;

                this.isLoggedIn         = true;
                this.timezone           = timezone;
                this.dashboardDevices   = user.dashboardDevices;
                this.userId             = user.id;
                this.itemAccessLevel    = user.itemAccessLevel;
                this.graphPpdp          = user.graphPpdp;

                if(devices.nodes.length === 0)
                    this.defaultDeviceId    = '';
                else
                    this.defaultDeviceId    = devices.nodes[0].id;

                return payload;
            },
            async logout(vue)
            {
                const apollo = vue.$apollo.getClient();
                await apollo.mutate({ mutation: require('../gql/mutations/logout.gql') });
                await onLogout(apollo);
                this.$reset();
            },
            async updateSettings(vue, patch)
            {
                const { data } = await vue.$apollo.mutate(
                    {
                        mutation: require('../gql/mutations/updateSettings.gql'),
                        variables: { id: this.userId, patch }
                    }
                );

                const user = data.updateUserById.user;

                this.itemAccessLevel    = user.itemAccessLevel;
                this.graphPpdp          = user.graphPpdp;
            },
            dtChangeFromSelector(newDt)
            {
                this.activeDt = newDt;
                this.returnDt = newDt;
                this.showTimeSelector = false;
            },
            /**
             * @param {{end: number, start: number}} newDt
             */
            dtChangeFromWidget(newDt)
            {
                this.activeDt = newDt;
            },
            dtReset()
            {
                this.activeDt = this.returnDt;
            },
            setCraneCardInterval(nodeId, intervalId)
            {
                Vue.set(this.craneCardIntervals, nodeId, intervalId);
            },
            setCraneCardChartType(nodeId, chartIdx, value)
            {
                const now = (this.craneCardChartTypes[nodeId] ?? []);
                now[chartIdx] = value;
                Vue.set(this.craneCardChartTypes, nodeId, now);
            },
            setDashboardDevices(vue, devices)
            {
                this.dashboardDevices = devices;
                updDashDevs(vue.$apollo.getClient(), this);
            },
            setWidgetResolution(widgetId, resolution)
            {
                Vue.set(this.widgetResolutions, widgetId, resolution);
            },
            /**
             * @param {string} name
             */
            openMenuSection(name)
            {
                const idx = this.openMenuSections.indexOf(name);
                if(idx !== -1)
                    this.openMenuSections.splice(idx, 1);
                else
                    this.openMenuSections = [name];

                localstorage_set(LS_OPENMENUSECTIONS, this.openMenuSections);
            },
            /**
             * @param {string} title
             * @param {boolean} withSearch
             * @param {TopActionInput[]} contextActions
             * @param {function} onReturn
             */
            topRegular(title, withSearch = true, contextActions = [], onReturn = function(){})
            {
                this.topActions     = contextActions.map(normalizeAction);
                this.topOnReturn    = onReturn;
                this.topTitle       = title;
                this.topType        = AndroidLikeTopbar.TOPTYPE_REGULAR;
                this.topWithSearch  = withSearch;
            },
            topReturnToRegular()
            {
                this.topType = AndroidLikeTopbar.TOPTYPE_REGULAR;
                this.topOnReturn();
            },
            topSearch()
            {
                this.topType = AndroidLikeTopbar.TOPTYPE_SEARCH;
            },
            topSelectWContext(selected)
            {
                this.topType = AndroidLikeTopbar.TOPTYPE_CONTEXTACTION;
                this.topSelected = selected;
            },
            /**
             * @param {string} title
             * @param {string} subtitle
             * @param {TopActionInput[]|TopActionInput} contextActions
             * @param {function} onReturn
             */
            topInContext(title = '', subtitle = '', contextActions = [], onReturn = null)
            {
                if(!isArray(contextActions))
                    contextActions = [contextActions];

                this.topActions     = contextActions.map(normalizeAction);
                this.topSubtitle    = subtitle;
                this.topType        = AndroidLikeTopbar.TOPTYPE_INCONTEXT;
                this.topTitle       = title;
                this.topOnReturn    = onReturn;
            },
            topReset()
            {
                this.topActions     = [];
                this.topSubtitle    = '';
                this.topType        = null;
                this.topTitle       = '';
                this.topOnReturn    = noop;
            },
            /**
             *
             * @param {TopActionInput[]|TopActionInput} contextActions
             */
            setTopActions(...contextActions)
            {
                const final = [];
                contextActions.forEach(
                    action =>
                    {
                        if(isArray(action))
                            action.forEach(a => final.push(a));
                        else
                            final.push(action);
                    }
                );

                this.topActions = final;
            },
            toggleTimeSelector()
            {
                this.showTimeSelector = !this.showTimeSelector;
            },
            annotateOpen(plotlyEvent, deviceNodeId)
            {
                const point = plotlyEvent.points[0];

                this.annotateDescription    = '';
                this.annotateDeviceNodeId   = deviceNodeId;
                this.annotateText           = '';
                this.annotateTsms           = point.data.x[point.pointIndex].getTime();
                this.annotateShowModal      = true;

                return new Promise(resolve => this.annotateResolve = resolve);
            },
            async annotateOpenExisting(vue, plotlyEvent)
            {
                const apollo = vue.$apollo.getClient();

                const { data: { annotationById } } = await apollo.query(
                    {
                        query: require('../App/Annotations/gql/fetchForEdit.gql'),
                        variables: { id: plotlyEvent.annotation.__annotationNodeId },
                        fetchPolicy: NETWORK_ONLY
                    }
                );

                this.annotateDescription    = annotationById.description;
                this.annotateDeviceNodeId   = annotationById.device.id;
                this.annotateEditId         = plotlyEvent.annotation.__annotationNodeId;
                this.annotateShowEdit       = true;
                this.annotateText           = annotationById.text;
                this.annotateTsms           = annotationById.tsms;

                return new Promise(resolve => this.annotateResolve = resolve);
            },
            async annotateAdd(vue, ok)
            {
                const apollo = vue.$apollo.getClient();

                await apollo.mutate(
                    {
                        mutation: require('../App/Annotations/gql/create.gql'),
                        variables:
                        {
                            annotation:
                            {
                                description: this.annotateDescription,
                                device: this.annotateDeviceNodeId,
                                text: this.annotateText,
                                tsms: this.annotateTsms,
                            }
                        }
                    }
                );

                this.annotateRevision++;
                this.annotateResolve();
                return ok();
            },
            async annotateEdit(vue, ok)
            {
                const apollo = vue.$apollo.getClient();

                await apollo.mutate(
                    {
                        mutation: require('../App/Annotations/gql/update.gql'),
                        variables:
                        {
                            input:
                            {
                                id: this.annotateEditId,
                                patch:
                                {
                                    description: this.annotateDescription,
                                    text: this.annotateText,
                                    tsms: this.annotateTsms
                                }
                            }
                        }
                    }
                );

                this.annotateRevision++;
                this.annotateResolve();
                return ok();
            },
            async annotationDelete(vue, ok)
            {
                const apollo = vue.$apollo.getClient();

                await apollo.mutate(
                    {
                        mutation: require('../App/Annotations/gql/delete.gql'),
                        variables: { input: { id: this.annotateEditId } }
                    }
                );

                this.annotateRevision++;
                this.annotateResolve();
                return ok();
            }
        },
        getters:
        {
            /**
             * @return {function(nodeId: string, defaultValue: string): string}
             */
            craneCardInterval(state)
            {
                return function(nodeId, defaultValue)
                {
                    return state.craneCardIntervals[nodeId] || defaultValue
                }
            },
            craneCardChartType(state)
            {
                return function(nodeId, defaultValue)
                {
                    return state.craneCardChartTypes[nodeId] || defaultValue;
                }
            },
            /**
             * @param state
             * @return {number}
             */
            safeGraphPpdp(state)
            {
                return clamp(state.graphPpdp, 1, 50);
            },
            widgetResolution(state)
            {
                return function(nodeId, defaultValue)
                {
                    return state.widgetResolutions[nodeId] || defaultValue;
                }
            }
        },
        state()
        {
            const openMenuSections = localstorage_get(LS_OPENMENUSECTIONS, []);
            const activeDt =
                {
                    end:    new Date().valueOf(),
                    start:  new Date().setHours(0, 0, 0, 0).valueOf(),
                }

            return {
                /** @type {{end: number, start: number}} */
                activeDt,
                /** @type {number} */
                graphPpdp: 3,
                /** @type {{end: number, start: number}} */
                returnDt: {...activeDt},
                /** @type {Object.<string, string>} */
                craneCardIntervals: {},
                /** @type {Object.<string, array>} */
                craneCardChartTypes: {},
                /** @type {number[]} */
                dashboardDevices: [],
                /** @type {number} */
                itemAccessLevel: 0,
                /** @type {boolean} */
                isLoggedIn: false,
                /** @type {number} UNIX timestamp in milliseconds */
                now: Date.now(),
                /** @type {string[]} */
                openMenuSections,
                /** @type {string} */
                timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
                /** @type TopAction[] */
                topActions:     [],
                topOnReturn:    noop,
                topSearchText:  '',
                topSelected:    [],
                topSubtitle:    '',
                topTitle:       '',
                topType:        '',
                topWithSearch:  true,
                /** @type {string} */
                reportWizSearch:        '',
                /** @type {boolean} */
                showTimeSelector:       false,
                annotateDescription:    '',
                annotateDeviceNodeId:   '',
                annotateEditId:         '',
                annotateResolve:        null,
                annotateRevision:       0,
                annotateShowEdit:       false,
                annotateShowModal:      false,
                annotateText:           '',
                annotateTsms:           0,
                schedReportsTableState: null,
                /** @type {string} */
                userId:                 '',
                usersTableState:        {},
                oilAnalysisTableState:  {},
                devicesTableState:      {},
                widgetResolutions:      {},
                defaultDeviceId:        '',
                [LIVE_DATA_CONFIG_TAB_IDX]:   0,
                [SOFTWARE_STATUS_TAB_IDX]:    0,
                enrollTableState:       {},
                lowEnd:                 false,
                enrollUrlRoot:          'http://localhost:83'
            }
        }
    }
);

/**
 * @typedef {Object} TopActionInput
 * @property {function(Event):Promise} [click]
 * @property {function} [condition]
 * @property {function: string} [to]
 * @property {string} [icon]
 * @property {string} [text]
 */


/**
 * @typedef {Object} TopAction
 * @property {function(Event):Promise} click
 * @property {function} condition
 * @property {function: string} to
 * @property {string} icon
 * @property {string} text
 */
