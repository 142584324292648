/**
 * @param {Blob} file
 * @return {Promise<string>} Base64 encoded string of `file`
 */
export default function (file)
{
    return new Promise(
        (resolve, reject) =>
        {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload =
                () =>
                {
                    let encoded = reader.result.toString().replace(/^data:(.*,)?/, '');

                    if ((encoded.length % 4) > 0)
                        encoded += '='.repeat(4 - (encoded.length % 4));

                    resolve(encoded);
                };
            reader.onerror = reject;
        }
    );
}