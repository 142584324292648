<template>
  <div>
    <b-table-simple table-class="mb-0" small>
      <b-tr>
        <b-td>E-Crane</b-td>
        <b-td>{{instance.device?.displayName}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Serial number</b-td>
        <b-td>{{instance.device?.craneSerial}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Project</b-td>
        <b-td>{{instance.device?.project}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Maintenance interval</b-td>
        <b-td>{{instance.maintInterval?.name}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Last performed</b-td>
        <b-td>{{instance.lastPerformed}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Current operating hours</b-td>
        <b-td>{{Math.floor(ophours / 3600)}} @ {{ophoursDate}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Average per day (last 90 days)</b-td>
        <b-td>{{instance.avgOphoursPerDay}} hours</b-td>
      </b-tr>
      <b-tr>
        <b-td>Due in</b-td>
        <b-td>{{instance.dueIn}} hours</b-td>
      </b-tr>
      <b-tr>
        <b-td>Predicted</b-td>
        <b-td>{{instance.lastPerformed + instance.maintInterval?.frequency}} hours reached on {{instance.suggest}}</b-td>
      </b-tr>
      <b-tr>
        <b-td>Updated</b-td>
        <b-td>{{instance.updated}}</b-td>
      </b-tr>
      <b-tr>
        <b-th colspan="2" class="text-center">Operating hours last 14 days</b-th>
      </b-tr>
      <b-tr>
        <b-td colspan="2">
          <vue-element-loading :active="loading" spinner="spinner"/>
          <chart-js :data="chartData" :height="200" :options="$options.miniGraphOptions" />
        </b-td>
      </b-tr>
    </b-table-simple>
  </div>

</template>

<script>
import get from 'lodash/get';


import {CACHE_AND_NETWORK, NETWORK_ONLY} from '@/lib/constants';
import store2           from '@/lib/store2';
import ChartJs                           from '@/components/ChartJs.vue';

export default
{
    components: { ChartJs },
    computed:
    {
        $state() { return store2(this.$pinia) },
    },
    apollo:
    {
        maintenanceById:
        {
            query: require('../gql/query/maintenanceModal.gql'),
            fetchPolicy: CACHE_AND_NETWORK,
            variables()
            {
                return { id: this.id, tz: this.$state.timezone }
            },
            result({data})
            {
                this.device = data.maintenanceById.device;
                this.ophours = JSON.parse(get(this.device.ophours, '[0].value', '0'));
                this.ophoursDate = get(this.device.ophours, '[0].ts');
                this.instance = data.maintenanceById;
                this.hourChart();
            }
        }
    },
    data()
    {
        return {
            instance: { },
            ophours: 0,
            ophoursDate: '',
            chartData:
            {
                labels: [],
                datasets:
                [
                    {
                        data: [],
                        borderColor: '#337ab7bb',
                        backgroundColor: '#337ab7'
                    }
                ]
            },
            loading: true
        }
    },
    methods:
    {
        async hourChart()
        {
            const { data: { maintenanceById: { device : { metricsOverTime }} } } = await this.$apollo.query(
                {
                    query: require('../gql/query/maintenanceModalOpHours.gql'),
                    variables: { id: this.id },
                    fetchPolicy: NETWORK_ONLY
                }
            );

            this.chartData.labels = metricsOverTime.timestamps;
            this.chartData.datasets[0].data = metricsOverTime.chart.y;
            this.loading = false;
        }
    },
    props:
    {
        id: { type: String, required: true }
    },
    miniGraphOptions:
    {
        animation: false,
        responsive: true,
        maintainAspectRatio: false,
        plugins:
        {
            legend: { display: false }
        },
        scales:
        {
            x:
            {
                title: { text: 'Day', display: true }
            },
            y:
            {
                title: { text: 'Hours', display: true }
            }
        },
        interaction: { intersect: false }
    }
}
</script>

<style>
</style>