<template>

  <div class="menu">
    <div class="left-menu-section" v-bind:class="{'left-menu-section-active': openMenuSections.indexOf('main') !== -1 }">

      <div class="left-menu-heading mb-2" @click="$state.openMenuSection('main')">
        <i class="fa fa-fw mr-2 fa-home section-icon"></i>Home
        <i class="fa fa-fw fa-chevron-down ml-auto"></i>
      </div>

      <router-link @click.native="linkClicked" :to="{ name: 'dashboard' }">Dashboard</router-link>
      <router-link @click.native="linkClicked" to="/app/deviceDetail">My dashboards</router-link>
      <router-link @click.native="linkClicked" to="/app/alarms">Alarms</router-link>
      <router-link @click.native="linkClicked" to="/app/cycles">Cycles</router-link>
      <router-link @click.native="linkClicked" to="/app/view/efficiency">Efficiency</router-link>
      <router-link @click.native="linkClicked" to="/app/annotations">Annotations</router-link>
    </div>

    <div class="left-menu-section" v-bind:class="{'left-menu-section-active': openMenuSections.indexOf('documentation') !== -1 }">
      <div class="left-menu-heading mb-2" @click="$state.openMenuSection('documentation')">
        <i class="fa fa-fw mr-2 fa-book section-icon"></i>
        Documentation
        <i class="fa fa-fw fa-chevron-down ml-auto"></i>
      </div>

      <router-link @click.native="linkClicked" :to="`/app/static/documentation/${defaultDeviceId}?view=schematics`" exact>Schematics</router-link>
      <router-link @click.native="linkClicked" :to="`/app/static/documentation/${defaultDeviceId}?view=testreports`" exact>Test reports</router-link>
    </div>

    <div class="left-menu-section" v-bind:class="{'left-menu-section-active': openMenuSections.indexOf('reports') !== -1 }">
      <div class="left-menu-heading mb-2" @click="$state.openMenuSection('reports')">
        <i class="fa fa-fw mr-2 fa-line-chart section-icon"></i>Reports
        <i class="fa fa-fw fa-chevron-down ml-auto"></i>
      </div>

      <router-link @click.native="linkClicked" to="/app/instantReport">Instant report</router-link>
      <router-link @click.native="linkClicked" to="/app/scheduledReports">Scheduled reports</router-link>
      <router-link @click.native="linkClicked" to="/app/reportHistory">History</router-link>
      <router-link @click.native="linkClicked" to="/app/legalCompliance">Legal compliance</router-link>
    </div>

    <div class="left-menu-section" v-bind:class="{'left-menu-section-active': openMenuSections.indexOf('service') !== -1 }">
      <div class="left-menu-heading mb-2" @click="$state.openMenuSection('service')">
        <i class="fa fa-fw mr-2 fa-handshake-o section-icon"></i>Service
        <i class="fa fa-fw fa-chevron-down ml-auto"/>
      </div>
      <router-link @click.native="linkClicked" :to="`/app/maintenanceCalendar`">Maintenance calendar</router-link>
      <router-link @click.native="linkClicked" :to="`/app/static/mainControllerBackup/${defaultDeviceId}`" v-if="$can('see', 'mainControllerBackup')">Main controller backup</router-link>
      <router-link @click.native="linkClicked" :to="`/app/oilSamples`" v-if="$can('see', 'oilSamples')">Oil samples</router-link>
    </div>

    <div class="left-menu-section" v-bind:class="{'left-menu-section-active': openMenuSections.indexOf('integrations') !== -1 }">
      <div class="left-menu-heading mb-2" @click="$state.openMenuSection('integrations')">
        <i class="fa fa-fw mr-2 fa-exchange section-icon"></i>Integrations
        <i class="fa fa-fw fa-chevron-down ml-auto"></i>
      </div>
      <router-link @click.native="linkClicked" to="/app/apiKeys">API keys</router-link>
      <router-link @click.native="linkClicked" to="/app/iso15143">ISO/TS 15143-3 (AEMP 2.0)</router-link>
    </div>

    <div class="left-menu-section"
         v-bind:class="{'left-menu-section-active': openMenuSections.indexOf('admin') !== -1}"
         v-if="$can('see', 'adminMenu')"
    >
      <div class="left-menu-heading mb-2" @click="$state.openMenuSection('admin')">
        <i class="fa fa-fw mr-2 fa-cubes section-icon"></i>
        Administration
        <i class="fa fa-fw fa-chevron-down ml-auto"/>
      </div>

      <router-link @click.native="linkClicked" to="/app/admin/users">Users</router-link>
      <router-link @click.native="linkClicked" to="/app/admin/groups">Groups</router-link>
      <router-link @click.native="linkClicked" to="/app/admin/devices">Devices</router-link>
    </div>
    </div>
  </div>

</template>


<script>
import store2 from '@/lib/store2';

export default
{
    name: 'MainMenu',
    computed:
    {
        $state() { return store2(this.$pinia) },
        openMenuSections() { return this.$state.openMenuSections },
        defaultDeviceId() { return this.$state.defaultDeviceId }
    },
    methods:
    {
        linkClicked()
        {
            this.$emit('link-clicked');
        }
    }
}
</script>

<style scoped>

    i.section-icon
    {
        font-size: 18px;
        width: 26px
    }

    .menu
    {
        background-color: #2A3F54;
        font-size: 13px;
    }

    .left-menu-heading
    {
        color: #E7E7E7;
        height: 45px;
        display: flex;
        align-items: baseline;
        padding: 13px 15px 12px 15px;
        width: 100%;
    }

    .left-menu-heading:hover
    {
        color: #F2F5F7 !important;
        cursor: pointer;
    }

    .left-menu-section-active
    {
        border-right: 5px solid #1ABB9C;
    }

    .left-menu-section-active > div:first-child
    {
        background: linear-gradient(#334556,#2C4257),#2A3F54;
        box-shadow: rgba(0,0,0,.25) 0 1px 0, inset rgba(255,255,255,.16) 0 1px 0;
    }

    .left-menu-section-active > a
    {
        display: flex !important;
    }

    .left-menu-section > a
    {
        display: none;
    }

    .left-menu-section > a
    {
        height: 35px;
        color: rgba(255,255,255,.75);
        align-items: center;
        padding-left: 3rem;
    }

    .left-menu-section > a::before
    {
        background: #425668;
        bottom: auto;
        content: "";
        height: 9px;
        position: absolute;
        left: 27px;
        width: 9px;
        border-radius: 50%;
    }

    .left-menu-section > a::after
    {
        border-left: 1px solid #425668;
        content: "";
        left: 31px;
        position: absolute;
        width: 1px;
        height: 35px;
    }

    .left-menu-section > .router-link-active
    {
        color: white;
        background-color: rgba(255,255,255,.05) ;
    }

    .left-menu-section > a:hover
    {
        background: rgba(255,255,255,.05);
        cursor: pointer;
        text-decoration: none;
    }

</style>
