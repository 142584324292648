// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import Vue from 'vue'


import {BootstrapVue, BootstrapVueIcons} from 'bootstrap-vue'
import VueElementLoading    from 'vue-element-loading'
import VueResize            from 'vue-resize';
import Vue2TouchEvents      from 'vue2-touch-events';
import { Ability }          from '@casl/ability';
import { abilitiesPlugin }      from '@casl/vue';
import { createPinia, PiniaVuePlugin, setMapStoreSuffix } from 'pinia';
import infiniteScroll       from 'vue-infinite-scroll'
import moment               from 'moment-timezone/builds/moment-timezone-with-data-10-year-range';

import GmapVue from 'gmap-vue';
import * as VueDeepSet from 'vue-deepset'

import Index from '@/Index'
import router from './router'
import number_format from './lib/number_format'

import 'vue-resize/dist/vue-resize.css';
import './assets/custom.scss';
import './assets/css/style.css';
import './assets/css/panels.css';
import './assets/css/main.scss';
import './assets/css/main_mobile.scss';

import { createProvider } from './vue-apollo'
import {
    ENV_VUE_APP_GRAPHQL_HTTP,
    ENV_VUE_APP_REST_HTTP
} from './lib/constants';
import subject_from_graphql                             from '@/lib/security/subject_from_graphql';
import BusyBtn              from '@/components/BusyBtn';
import store2               from '@/lib/store2';
import Router               from 'vue-router';

setMapStoreSuffix('');

Vue.use(BootstrapVue,
    {
        BFormGroup:
        {
            labelClass: 'font-weight-bold'
        },
        BModal:
        {
            cancelVariant: 'outline-secondary'
        },
        BButton:
        {
            variant: 'primary'
        },
        breakpoints: ['xs', 'sm', 'md', 'lg', 'xl', 'xl2', 'xl3']
    }
);
Vue.use(BootstrapVueIcons);
Vue.use(GmapVue, {
    load: {
        key: 'AIzaSyDR1VTsRIcv3PTXzmM2p2cfoBE6tIzX7kw',
        // libraries: 'places', // This is required if you use the Autocomplete plugin
        // OR: libraries: 'places,drawing'
        // OR: libraries: 'places,drawing,visualization'
        // (as you require)

        //// If you want to set the version, you can do so:
        // v: '3.26',
    },

    //// If you intend to programmatically custom event listener code
    //// (e.g. `this.$refs.gmap.$on('zoom_changed', someFunc)`)
    //// instead of going through Vue templates (e.g. `<GmapMap @zoom_changed="someFunc">`)
    //// you might need to turn this on.
    // autobindAllEvents: false,

    //// If you want to manually install components, e.g.
    //// import {GmapMarker} from 'vue2-google-maps/src/components/marker'
    //// Vue.component('GmapMarker', GmapMarker)
    //// then disable the following:
    // installComponents: true,
});

Vue.use(PiniaVuePlugin); // PiniaVuePlugin must be installed before Router (https://github.com/vuejs/pinia/discussions/723)
Vue.use(Router);
Vue.use(VueDeepSet);
Vue.use(VueResize);
Vue.use(abilitiesPlugin, new Ability([], { detectSubjectType: subject_from_graphql }));
Vue.use(infiniteScroll);
Vue.use(
    Vue2TouchEvents,
    {
        swipeTolerance: 60,
        disableClick: true
    }
);

Vue.component('VueElementLoading', VueElementLoading);
Vue.component('BusyBtn', BusyBtn);


Vue.config.productionTip = false;


Vue.filter('number_format', number_format);

Vue.filter('fullDate',
    function(value, tz)
    {
        if (value)
        {
            if(tz)
                return moment(value).tz(tz).format('YYYY-MM-DD HH:mm:ss');
            else
                return moment(value).format('YYYY-MM-DD HH:mm:ss');
        }
    }
);

Vue.filter('dateFmt',
    function(value, format)
    {
        if(value)
            return moment(value).format(format);
    }
);

Vue.filter('emptyStr',
    function(val)
    {
        if(val === undefined)
            return '_';

        return val;
    }
);

Vue.filter('ucfirst',
    function(val)
    {
        return val.charAt(0).toUpperCase() + val.slice(1);
    }
);

console.log('Version:', APP_VERSION);

let wsEndpoint = location.protocol === 'https:' ? 'wss://' : 'ws://';

wsEndpoint += `${location.host}${process.env.VUE_APP_GRAPHQL_WS_REL}?v=${APP_VERSION}`;

const httpEndpoint = process.env[ENV_VUE_APP_GRAPHQL_HTTP] + `?v=${APP_VERSION}`;

Vue.prototype.$apolloHeaders = { Prefer: `timezone=${Intl.DateTimeFormat().resolvedOptions().timeZone}` };

const apolloProvider = createProvider(
    {
        httpEndpoint,
        wsEndpoint,
        headers: Vue.prototype.$apolloHeaders
    }
);

async function start()
{
    const pinia = createPinia();

    router.beforeEach(
        async (to, from, next) =>
        {
            const store = store2(pinia);
            store.topReset();

            if (to.matched.some(record => record.meta.requiresAuth))
            {
                if(store.isLoggedIn)
                {
                    next();
                }
                else
                {
                    // TODO: Handle failure
                    try
                    {
                        const { data } = await apolloProvider.defaultClient.mutate({ mutation: require('./gql/mutations/loginCheck.gql') });

                        if(data.loginCheck.valid)
                        {
                            await store.loginExisting(window.app);
                            return next();
                        }
                        else
                        {
                            return next({ path: '/login', query: { redirect: to.fullPath }});
                        }
                    }
                    catch (e)
                    {
                        console.error(e);
                    }

                    return next({ path: '/login', query: { redirect: to.fullPath }});
                }
            }
            else
            {
                next();
            }
        }
    );

    window.app = new Vue(
        {
            apolloProvider,
            components: { Index },
            pinia,
            router,
            template: '<Index/>',
            render: h => h(Index)
        }
    ).$mount('#app');
}

start().then();