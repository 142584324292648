<template>
  <div>
    <div class="row mobile-row-nogutters" v-infinite-scroll="loadMore" :infinite-scroll-disabled="busy" :infinite-scroll-distance="10" :infinite-scroll-immediate-check="false">
      <div class="col-12 col-md-6 col-xl-4 col-xl2-3" v-for="({node}) in devices" :key="node.id">
        <crane-card :id="node.id" :device-id="node.deviceId" @pingClicked="id => { infoId = id; showStatusModal = true }"/>
      </div>
    </div>
    <b-modal v-model="showStatusModal" centered size="lg" ok-only ok-title="Close" title="Telemetry software status" scrollable>
      <telemetry-software-status-modal :id="infoId" ref="statusModal" />
      <template v-slot:modal-footer="{ok, cancel}">
        <busy-btn variant="secondary" @click="$refs.statusModal.refresh()">Refresh</busy-btn>
        <b-btn variant="primary" @click="ok">Close</b-btn>
      </template>
    </b-modal>
  </div>

</template>

<script>

import CraneCard           from '../../../Main/CraneCard';
import {CACHE_AND_NETWORK} from '../../../lib/constants';
import store2              from '@/lib/store2';
import TelemetrySoftwareStatusModal from '@/components/TelemetrySoftwareStatusModal.vue';

export default
{
    components: {TelemetrySoftwareStatusModal, CraneCard},
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    data()
    {
        return {
            devices: [],
            pageInfo: {},
            busy: false,
            lastCursor: null,
            showStatusModal: false,
            infoId: null
        }
    },
    apollo:
    {
        devices:
        {
            query: require('../../../gql/dashboardFull.gql'),
            variables()
            {
                if(this.$state.dashboardDevices.length === 0)
                {
                    return { after: null, first: 10 }
                }
                else
                {
                    return {
                        after: null,
                        first: 999,
                        filter:
                        {
                            deviceId: { in: this.$state.dashboardDevices }
                        }
                    }
                }
            },
            result({data})
            {
                if(data === undefined)
                    return;

                const arr = data.devices.edges;

                if(arr.length === 0)
                    this.lastCursor = null;
                else
                    this.lastCursor = arr[arr.length - 1].cursor;

                this.devices = arr;
            },
            fetchPolicy: CACHE_AND_NETWORK,
            manual: true
        }
    },
    methods:
    {
        async loadMore()
        {
            if(
                this.pageInfo.hasNextPage === false
                || this.lastCursor === null
                || this.busy
                || this.$state.dashboardDevices.length > 0)
                return;

            this.busy = true;

            const { data } = await this.$apollo.query(
                {
                    query: require('../../../gql/dashboardFull.gql'),
                    variables: { after: this.lastCursor, first: 10 }
                }
            );

            this.$vueSet(this, 'pageInfo', data.devices.pageInfo);

            const edges = data.devices.edges;

            if(edges.length !== 0)
            {
                this.devices = [...this.devices, ...edges];
                this.lastCursor = edges[edges.length - 1].cursor;
            }

            this.busy = false;
        }
    },
}
</script>

<style>
</style>