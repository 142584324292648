<template>

  <div class="panel">
    <div class="panel-heading">
      <h3>
        <fit-text>
          <a href="#" @click.prevent="myDashboards(device)">{{device.displayName}}</a>
        </fit-text>
      </h3>
    </div>
    <div class="panel-content">
      <div class="d-flex">
        <div class="flex-grow-1 mr-2" style="min-width: 120px;">
          <p class="mb-1" v-if="maintenanceDue > 48"><i class="fa fa-fw fa-wrench"/> OK</p>
          <p class="mb-1 text-warning" v-if="maintenanceDue <= 48 && maintenanceDue > 0">
            <i class="fa fa-fw fa-wrench"/> Due in {{maintenanceDue}} hours
          </p>
          <p class="mb-1 text-danger" v-if="maintenanceDue < 0">
            <i class="fa fa-fw fa-wrench"/> Overdue
          </p>
            <template v-if="ping === null"><p class="mb-1"><i class="fa fa-fw fa-rss"/> Unknown</p></template>
            <template v-else>
                <p v-bind:class="{ 'text-success': pingWarn === $options.PING_OK, 'text-warning': pingWarn === $options.PING_WARNING, 'text-danger': pingWarn === $options.PING_DANGER }"
                    class="mb-1">
                    <i class="fa fa-fw fa-rss"/> {{lastPing.humanize()}}
                </p>
            </template>
          <img :alt="device.country.al2" :src="device.country.flag" class="mini-flag"> {{device.country.al2}}
        </div>
        <div class="d-flex circle justify-content-center" :class="{'motor-on': motorOn === 1}">
          <div class="align-self-center" style="font-size: 1.6em">
            {{kgph / 1000 | number_format(0, ',', '')}}
          </div>
          <div style="position: absolute; top: 65%">t/h</div>
        </div>
      </div>

    </div>
  </div>

</template>

<script>
import get from 'lodash/get';

import FitText         from '../components/FitText';
import craneCardCommon from '../lib/craneCardCommon';
import store2          from '@/lib/store2';


export default
{
    apollo:
    {
        $subscribe:
        {
            telemData:
            {
                query: require('../gql/live/mini_cranecard.gql'),
                variables() { return { id: this.id, maintFields: this.$options.maintFields } },
                result({data: { deviceById }})
                {
                    this.ping       = deviceById.ping;
                    this.motorOn    = JSON.parse(get(deviceById.motorOn.nodes, '[0].value', '0'));
                    this.kgph       = JSON.parse(get(deviceById.kgph.nodes, '[0].value', '0'));
                    this.maintenances = deviceById.maintenances.nodes;
                },
            }
        },
        device:
        {
            query: require('../gql/MiniCraneCard.gql'),
            variables() { return { id: this.id } }
        }
    },
    components:
    {
        FitText
    },
    computed:
    {
        $state() { return store2(this.$pinia) },
        lastPing: craneCardCommon.lastPing,
        pingWarn: craneCardCommon.pingWarn,
        maintenanceDue()
        {
            return this.maintenances.reduce((acc, val) => val.dueIn < acc ? val.dueIn : acc, Number.POSITIVE_INFINITY);
        }
    },
    data()
    {
        return {
            device: { country: {} },
            ping: null,
            motorOn: null,
            kgph: 0,
            maintenances: [[], [], [], []],
            ophours: 0
        }
    },
    methods:
    {
        myDashboards(device)
        {
            this.$state.defaultDeviceId = device.id;
            this.$router.push(`/app/deviceDetail/${this.$state.defaultDeviceId}`);
        },
    },
    props:
    {
        id: { type: [Number, String], required: true }
    },
    PING_OK:       0,
    PING_WARNING:  1,
    PING_DANGER:   2,
    maintFields: {in:['dueIn']}
}
</script>

<style scoped>
  div.circle
  {
    border: 10px solid rgb(189, 195, 199);
    border-radius: 50%;
    width: 6em;
    height: 6em;
  }
  .motor-on
  {
    border: 10px solid #26b99a !important;
  }


  .pulse2 {
    animation: pulse 800ms infinite alternate;
  }
  @-webkit-keyframes pulse {
    0% { color: #dc3545; }
    100% { }
  }
  @keyframes pulse {
    0% { color: #dc3545; }
    100% {  }
  }
</style>