<template>
  <b-form>
    <b-form-group label="Date when the sample was sent to the lab">
      <b-datepicker v-model="sentToLabDateHtml" today-button />
    </b-form-group>
    <b-form-group label="Provider" :state="fields.provider.state" class="form-group-d-flex">
      <template v-if="addProvider">
        <b-input ref="newProviderInput" autofocus v-model="fields.newProviderName.value" :state="fields.newProviderName.state" placeholder="Company and oil analysis service name" />
        <b-btn class="ml-2" variant="outline-warning" @click="switchToSelProvider"><i class="fa fa-fw fa-times"></i></b-btn>
      </template>
      <template v-else>
        <b-select :options="providers" :state="fields.provider.state" v-model="fields.provider.value" @change="providerChanged">
          <template #first>
            <b-select-option :value="null" disabled>-- Select an option --</b-select-option>
            <option value="new" style="font-style: italic;">&nbsp;Other</option>
          </template>
        </b-select>
      </template>
    </b-form-group>
  </b-form>

</template>

<script>

import {CACHE_AND_NETWORK} from '@/lib/constants';
import moment              from 'moment/moment';
import {DATE_INPUT_FMT}    from '@/lib/dateComputed';
import stubTrue            from 'lodash/stubTrue';
import noop                    from 'lodash/noop';
import {ff, ffCollectionToGql} from '@/lib/ff.mjs';

export default
{
    apollo:
    {
        seed:
        {
            query: require('../gql/query/sendOilSampleToLabSeed.gql'),
            fetchPolicy: CACHE_AND_NETWORK,
            manual: true,
            variables() { return { id: this.id } },
            result({data})
            {
                this.providers = data.oilAnalysisProviders.nodes;
            }
        }
    },
    computed:
    {
        sentToLabDateHtml:
        {
            get()
            {
                return moment(this.fields.sentToLab.value).format(DATE_INPUT_FMT);
            },
            set(v)
            {
                this.fields.sentToLab.value = moment(v, DATE_INPUT_FMT).valueOf();
            }
        },
    },
    data()
    {
        return {
            providers: [],
            addProvider: false,
            fields:
            {
                sentToLab: ff(Date.now(), stubTrue),
                provider: ff(null, v => this.addProvider ? null : v !== null, v => v === null ? undefined : v),
                newProviderName: ff('', v => this.addProvider ? v !== '' : null, v => v === '' ? undefined : v)
            }
        }
    },
    methods:
    {
        async submitComplete(ok = noop)
        {
            const changes = await ffCollectionToGql(this.fields);

            if(changes === null)
                return;

            await this.$apollo.mutate(
                {
                    mutation: require('../gql/mutations/sentOilSampleToLab.gql'),
                    variables: { input: { ids: [this.id], changes }}
                }
            );

            await ok();
        },
        switchToSelProvider()
        {
            this.addProvider = false;
            this.fields.provider.value = null;
        },
        providerChanged(e)
        {
            this.fields.provider.state = null;
            this.fields.newProviderName.state = null;

            if(e !== 'new')
                return;

            this.addProvider = true;
        },
    },
    props:
    {
        id: { type: String }
    }
}
</script>

<style>
</style>