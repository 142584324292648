<template>
  <router-view></router-view>
</template>

<script>
import store2 from '@/lib/store2';

export default
{
    beforeDestroy()
    {
        clearInterval(this.updateNow);
        clearInterval(this.loginCheck);
    },
    data()
    {
        return {
            loginCheck: 0,
            updateNow: 0
        }
    },
    mounted()
    {
        const store     = store2(this.$pinia);

        this.updateNow  = setInterval(() => store.now = Date.now(), 60 * 1000);

        this.loginCheck = setInterval(
            async () =>
            {
                if(!store.isLoggedIn)
                    return;

                try
                {
                    const { data } = await this.$apollo.mutate({ mutation: require('./gql/mutations/loginCheck.gql') });

                    if(data.loginCheck.expires < (Date.now() + 86400000))
                    {
                        console.info('Session expired, logging out');
                        await this.$router.replace('/logout')
                    }
                }
                catch (e) {}
            },
            60 * 1000 * 10 // Every 10 minutes
        );
    }
}
</script>