import isString from 'lodash/isString';

export default async function(router, route)
{
    let destination = route.query.redirect;

    if(!(isString(destination) && destination.length > 0))
        destination = '/app';

    return router.replace(destination);
}