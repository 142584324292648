<template>
  <b-tabs content-class="pt-2" v-model="tabIdx" class="modal-content-large-h">
    <b-tab title="Info">
      <b-table-simple table-class="mb-0" small>
        <b-tr>
          <b-td>Telemetry version</b-td>
          <b-td>{{deviceById.ping?.version}}</b-td>
        </b-tr>
        <b-tr>
          <b-td>Main controller version</b-td>
          <b-td>{{deviceById.lasts?.nodes[0]?.value}}</b-td>
        </b-tr>
      </b-table-simple>
    </b-tab>
    <b-tab>
      <template #title>
        Health <i class="fa fa-fw" :class="healthIconClass"></i>
      </template>
      <b-alert v-if="loadFailed" show variant="warning">Unable to contact telemetry software</b-alert>
      <b-table :items="health" small :fields="$options.healthFields" striped v-else-if="!loading">
        <template #row-details="row">
          <b-alert show variant="danger" class="mb-0 p-2">{{row.item.message}}</b-alert>
        </template>
        <template #cell(status)="row">
          <i class="fa fa-fw" :class="row.item._iconClass"></i>
        </template>
      </b-table>
    </b-tab>
    <b-tab>
      <template #title>
        Services <i class="fa fa-fw" :class="serviceIconClass"></i>
      </template>
      <b-alert v-if="loadFailed" show variant="warning">Unable to contact telemetry software</b-alert>
      <b-table :items="services" small :fields="$options.serviceFields" striped v-else-if="!loading">
        <template #row-details="row">
          <b-alert show variant="danger" class="mb-0 p-2">{{row.item.failureCause}}</b-alert>
        </template>
        <template #cell(state)="row">
          <i class="fa fa-fw" :class="row.item._iconClass"></i> {{row.value}}
        </template>
      </b-table>
    </b-tab>
    <b-tab>
      <template #title>Connections</template>
      <b-alert v-if="deviceById.connections.nodes.length === 0" show variant="warning">
        No active connections, host may be offline
      </b-alert>
      <b-table v-else :items="deviceById.connections.nodes" :fields="$options.connectionFields" small />
    </b-tab>
  </b-tabs>
</template>

<script>

import {CACHE_AND_NETWORK, NETWORK_ONLY} from '@/lib/constants';
import moment                            from 'moment/moment';
import {mapWritableState}                                          from 'pinia';
import store2, {SOFTWARE_STATUS_TAB_IDX} from '@/lib/store2';
import ChartJs from '@/components/ChartJs.vue';

export default
{
    components: {ChartJs},
    apollo:
    {
        deviceById:
        {
            query: require('../gql/query/softwareStatus.gql'),
            variables() { return { id: this.id } },
            fetchPolicy: CACHE_AND_NETWORK
        }
    },
    computed:
    {
        healthIconClass()
        {
            if(this.loading)
            {
                return 'fa-spinner fa-spin';
            }
            else if(this.loadFailed)
            {
                return 'fa-ban';
            }
            else
            {
                if(this.health.some((row) => !row.isHealthy))
                    return 'fa-exclamation-triangle text-danger';
                else
                    return 'fa-check text-success';
            }
        },
        serviceIconClass()
        {
            if(this.loading)
            {
                return 'fa-spinner fa-spin';
            }
            else if(this.loadFailed)
            {
                return 'fa-ban';
            }
            else
            {
                if(this.services.some((row) => row.state !== 'RUNNING'))
                    return 'fa-exclamation-triangle text-danger';
                else
                    return 'fa-check text-success';
            }
        },
        ...mapWritableState(store2, {tabIdx: SOFTWARE_STATUS_TAB_IDX}),
    },
    data()
    {
        return {
            loading: true,
            loadFailed: false,
            health:   [],
            services: [],
            deviceById: { ping: {}, connections: { nodes: [] } }
        }
    },
    props:
    {
        id: { type: String, required: true }
    },
    async mounted()
    {
        await this.loadData();
    },
    methods:
    {
        async loadData()
        {
            this.loading    = true;
            this.loadFailed = false;

            this.$apollo.queries.deviceById.refetch();

            try
            {
                const res = await this.$apollo.query(
                    {
                        query: require('../gql/query/softwareState.gql'),
                        variables: { id: this.id },
                        fetchPolicy: NETWORK_ONLY
                    }
                );

                this.health = res.data.deviceById.softwareState.healthChecks
                    .map(check =>
                        {
                            return {
                                ...check,
                                status: check.isHealthy,
                                _showDetails: !check.isHealthy,
                                _iconClass: check.isHealthy ? 'fa-check text-success' : 'fa-exclamation-triangle text-danger'
                            }
                        }
                    );

                this.services = res.data.deviceById.softwareState.services
                    .map(service =>
                        {
                            const isOk = service.state === 'RUNNING';
                            return {
                                ...service,
                                _showDetails: !isOk,
                                _iconClass: isOk ? 'fa-check text-success' : 'fa-exclamation-triangle text-danger'
                            }
                        }
                    );
            }
            catch (e)
            {
                console.error(e);
                this.loadFailed = true;
            }

            this.loading = false;
        },
        async refresh()
        {
            await this.loadData();
        },
    },
    connectionFields:
    [
        { key: 'ip', label: 'IP' },
        { key: 'port', label: 'Port' }
    ],
    healthFields:
    [
        { key: 'name' },
        {
            key: 'time',
            formatter: v => moment.duration(Date.now() - v).humanize() + ' ago'
        },
        { key: 'status' }
    ],
    serviceFields:
    [
        { key: 'name' },
        { key: 'state' }
    ],
    SOFTWARE_STATUS_TAB_IDX
}
</script>

<style>
</style>