<template>
  <div class="cssload-container">
    <div class="cssload-loading"><i></i><i></i></div>
  </div>
</template>

<script>
export default
{

}
</script>

<style scoped>
  .cssload-container{
    display: block;
    margin: auto;
    width:16px;
  }

  .cssload-loading{
    width: 15px;
    height: 15px;
    text-align: left;
    animation:cssload-loading-ani1 2s ease-in-out infinite;
    -o-animation:cssload-loading-ani1 2s ease-in-out infinite;
    -ms-animation:cssload-loading-ani1 2s ease-in-out infinite;
    -webkit-animation:cssload-loading-ani1 2s ease-in-out infinite;
    -moz-animation:cssload-loading-ani1 2s ease-in-out infinite;
  }
  .cssload-loading i,
  .cssload-loading i:before{
    content: "";
    width: 15px;
    height: 15px;
    position: absolute;
  }
  .cssload-loading i{
    border-radius: 50%;
    overflow: hidden;
  }
  .cssload-loading i:nth-child(1){
    background: rgb(31,118,180);
    transform:translate(0,-8px);
    -o-transform:translate(0,-8px);
    -ms-transform:translate(0,-8px);
    -webkit-transform:translate(0,-8px);
    -moz-transform:translate(0,-8px);
  }
  .cssload-loading i:nth-child(1):before{
    background: rgb(255,126,14);
    bottom: 0;
    animation:cssload-loading-ani2 2s ease-in-out infinite;
    -o-animation:cssload-loading-ani2 2s ease-in-out infinite;
    -ms-animation:cssload-loading-ani2 2s ease-in-out infinite;
    -webkit-animation:cssload-loading-ani2 2s ease-in-out infinite;
    -moz-animation:cssload-loading-ani2 2s ease-in-out infinite;
  }
  .cssload-loading i:nth-child(2){
    background: rgb(214,39,39);
    transform:translate(0,8px);
    -o-transform:translate(0,8px);
    -ms-transform:translate(0,8px);
    -webkit-transform:translate(0,8px);
    -moz-transform:translate(0,8px);
  }
  .cssload-loading i:nth-child(2):before{
    animation:cssload-loading-ani2 2s ease-in-out infinite;
    -o-animation:cssload-loading-ani2 2s ease-in-out infinite;
    -ms-animation:cssload-loading-ani2 2s ease-in-out infinite;
    -webkit-animation:cssload-loading-ani2 2s ease-in-out infinite;
    -moz-animation:cssload-loading-ani2 2s ease-in-out infinite;
    background: rgb(44,160,44);
  }



  @keyframes cssload-loading-ani1{
    40%,50%{
      transform:rotate(180deg);
    }
    90%,100%{
      transform:rotate(360deg);
    }
  }

  @-o-keyframes cssload-loading-ani1{
    40%,50%{
      -o-transform:rotate(180deg);
    }
    90%,100%{
      -o-transform:rotate(360deg);
    }
  }

  @-ms-keyframes cssload-loading-ani1{
    40%,50%{
      -ms-transform:rotate(180deg);
    }
    90%,100%{
      -ms-transform:rotate(360deg);
    }
  }

  @-webkit-keyframes cssload-loading-ani1{
    40%,50%{
      -webkit-transform:rotate(180deg);
    }
    90%,100%{
      -webkit-transform:rotate(360deg);
    }
  }

  @-moz-keyframes cssload-loading-ani1{
    40%,50%{
      -moz-transform:rotate(180deg);
    }
    90%,100%{
      -moz-transform:rotate(360deg);
    }
  }

  @keyframes cssload-loading-ani2{
    40%,100%{
      height:100%;
    }
    50%,90%{
      height:0;
    }
  }

  @-o-keyframes cssload-loading-ani2{
    40%,100%{
      height:100%;
    }
    50%,90%{
      height:0;
    }
  }

  @-ms-keyframes cssload-loading-ani2{
    40%,100%{
      height:100%;
    }
    50%,90%{
      height:0;
    }
  }

  @-webkit-keyframes cssload-loading-ani2{
    40%,100%{
      height:100%;
    }
    50%,90%{
      height:0;
    }
  }

  @-moz-keyframes cssload-loading-ani2{
    40%,100%{
      height:100%;
    }
    50%,90%{
      height:0;
    }
  }
</style>