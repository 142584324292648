import Vue from 'vue';
import VueApollo from 'vue-apollo';
import { createApolloClient } from './lib/graphqlClient';

// Install the vue plugin
Vue.use(VueApollo);

// Name of the localStorage item
const AUTH_TOKEN = 'apollo-token';

// Config
const defaultOptions =
{
    httpEndpoint: process.env.VUE_APP_GRAPHQL_HTTP || 'http://localhost:4000/graphql',
    wsEndpoint: process.env.VUE_APP_GRAPHQL_WS || 'ws://localhost:4000/graphql',
}

// Call this in the Vue app file
export function createProvider (options = {})
{
    const { apolloClient, restartWs } = createApolloClient({ ...defaultOptions, ...options });
    apolloClient.restartWs = restartWs;

    return new VueApollo(
        {
            defaultClient: apolloClient,
            defaultOptions:
            {
                $query:
                {
                    // fetchPolicy: 'cache-and-network',
                }
            },
            errorHandler (error)
            {
                console.log('%cError', 'background: red; color: white; padding: 2px 4px; border-radius: 3px; font-weight: bold;', error.message)
            }
        }
    );
}

// Manually call this when user log in
export async function onLogin (apolloClient, token)
{
    if (typeof localStorage !== 'undefined' && token)
        localStorage.setItem(AUTH_TOKEN, token)

    if(apolloClient.wsRestart)
        apolloClient.wsRestart();

    try
    {
        // await apolloClient.resetStore()
    }
    catch (e) { console.log('%cError on cache reset (login)', 'color: orange;', e.message) }
}

// Manually call this when user log out
export async function onLogout (apolloClient)
{
    if (typeof localStorage !== 'undefined')
        localStorage.removeItem(AUTH_TOKEN)

    if(apolloClient.wsRestart)
        apolloClient.wsRestart();

    try
    {
        await apolloClient.resetStore()
    }
    catch (e) { console.log('%cError on cache reset (logout)', 'color: orange;', e.message) }
}
