import get from 'lodash/get';

function feedback(instance, err, msgBoxOptions)
{
    const nodes = [];

    if(err.networkError)
    {
        const list = err.networkError.result.errors.map(
            (netErr) =>
                instance.$createElement('li', netErr.message)
        );
        nodes.push(instance.$createElement('b', 'Network errors:'));
        nodes.push(instance.$createElement('ul', list));
    }

    if(err.graphQLErrors)
    {
        let ele;

        if(err.graphQLErrors.length > 1)
        {
            const list = err.graphQLErrors.map(
                (gqlErr) =>
                    instance.$createElement('li', gqlErr.message)
            );
            ele = instance.$createElement('ul', list);
        }
        else
        {
            ele = instance.$createElement('span',
                get(err.graphQLErrors[0], 'extensions.appMessage', err.graphQLErrors[0].message)
            );
        }

        nodes.push(ele);
    }

    if(typeof err === 'string')
    {
        nodes.push(instance.$createElement('span', err));
    }

    console.error(err);

    return instance.$bvModal.msgBoxOk(nodes, msgBoxOptions);
}

export default feedback;