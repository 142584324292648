<template>
  <b-container fluid>
    <b-row class="single-stat-collection row">
      <b-col :cols="cols" :md="3" :lg="lgCols" :xl="xlCols" :xl2="xl2Cols" v-for="x in $options.items" :key="`s_${x.value}`">
        <div><i class="fa fa-fw" :class="`fa-${x.icon}`"></i> {{x.text}}</div>
        <div>{{values[x.value] ?? '-'}}</div>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>

import OilSampleStatus from '@/lib/enums/OilSampleStatus.mjs';

export default
{
    apollo:
    {
        $subscribe:
        {
            items:
            {
                query: require('@/gql/live/oilSampleStats.gql'),
                result({data})
                {
                    data.oilSampleStats.nodes.forEach(node => this.$set(this.values, node.status, node.count));
                }
            }
        }
    },
    data()
    {
        return {
            cols: 4,
            mdCols: 3,
            lgCols: 2,
            xlCols: 2,
            xl2Cols: 1,
            values: {}
        }
    },
    items: Object.values(OilSampleStatus)
}
</script>

<style lang="scss">
div.single-stat-collection
{
    > div
    {
        /*
        outline: #dc3545 dashed 1px;
        */
    }
    > div::before
    {
        content: "";
        position: absolute;
        left: 0;
        height: 44px;
        border-left: 2px solid #ADB2B5;
        margin-top: 10px;
    }
    /*> div:first-child::before
    {
        border-left: 0;
    }*/
    > div > div:nth-child(2)
    {
        /*
        outline: #337ab7 dashed 1px;
        */
        font-size: 40px;
        font-weight: 600;
        line-height: 47px;
    }

}
</style>