<template>
  <div class="progress-bar-wrapper" style="width:100%">
    <div class="status-bar" :style="statusBarStyle">
      <div class="current-status" :style="progressStyle"></div>
    </div>
    <div class="buttons">
      <div v-for="(step, idx) in steps" :class="{visited: idx <= position, current: idx === position}">{{step['text']}}<div></div></div>
    </div>
  </div>

</template>

<script>

export default
{
    computed:
    {
        position()
        {
            return this.steps.findIndex(s => s.value === this.value);
        },
        statusBarStyle()
        {
            return { width: ((this.steps.length - 1) / this.steps.length) * 100 + '%' };
        },
        progressStyle()
        {
            return {width: (this.position / (this.steps.length - 1)) * 100 + '%' };
        }
    },
    mounted()
    {
    },
    props:
    {
        steps: { type: Array, required: true },
        value: { type: String }
    }
}
</script>

<style lang="scss">

div.buttons
{
    display: flex;
    div
    {
        display: inline-block;
        flex-grow: 1;
        padding-top: 45px;
        font-weight: bold;
        line-height: 16px;
        vertical-align: top;
        position: relative;
        text-align: center;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    div:before
    {
        content: '✕';
        position: absolute;
        top: 2px;
        left: calc(50% - 15px);
        z-index: 1;
        width: 30px;
        height: 30px;
        color: white;
        border: 2px solid white;
        border-radius: 17px;
        line-height: 2.2;
        background: #5a6268;
        text-size-adjust: none;
    }
}

div.progress-bar {
    width: 100%;
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style: none;
}

.status-bar {
    height: 2px;
    background: #5a6268;
    position: relative;
    top: 20px;
    margin: 0 auto;
}
.current-status {
    height: 2px;
    width: 0;
    border-radius: 1px;
    background: #26b99a;
}

.visited:before {
    content: '\2713' !important;
    background: #26b99a !important;
}

.visited.current:before {
    box-shadow: 0 0 0 2px #26b99a   ;
}
.visited.current
{
  color: #26b99a;
}
</style>