<template>
  <div>
    <div class="panel">
      <div class="panel-heading">
        <h3>Legal compliance</h3>
      </div>
      <div class="panel-content">

        <p>Type of document:</p>
        <b-btn variant="link" to="/paper/sgdatalogger">Singapore: data loggers for mobile cranes (OSHD/SPECS/ESB/LE/02/2015)</b-btn>
      </div>
    </div>
  </div>

</template>

<script>
export default
{
    data()
    {
        return {
            type: null
        }
    },
}
</script>

<style>
</style>