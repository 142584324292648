<template>

  <b-modal centered :visible="value" title="Change password" @change="v => $emit('input', v)" @hidden="reset">
    <template v-slot:default="{ok}">
      <div style="height: 18rem" class="d-flex align-items-center">
        <b-form class="flex-fill" v-show="state === $options.STATE_SET">
          <b-form-group label="Username:">
            <b-form-input readonly :value="user.email" autocomplete="username"></b-form-input>
          </b-form-group>
          <b-form-group id="input-group-1" label="Old password:" label-for="input-1" label-class="font-weight-bold">
            <b-form-input id="input-1" type="password" required v-model="oldPw" autocomplete="current-password"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-2" label="New password:" label-for="input-2" label-class="font-weight-bold"
          :invalid-feedback="pw1invalidFeedback" :state="pw1state" valid-feedback="Looks good to me">
            <b-form-input id="input-2" required type="password" v-model="newPw1" :state="pw1state" autocomplete="new-password"></b-form-input>
          </b-form-group>

          <b-form-group id="input-group-3" label="Confirm new password:" label-for="input-3" label-class="font-weight-bold">
            <b-form-input id="input-3" required type="password" v-model="newPw2" autocomplete="new-password"></b-form-input>
          </b-form-group>
          <div class="text-center">

          </div>
        </b-form>
        <div class="flex-fill text-center" style="font-size: 200%" v-show="state === $options.STATE_SUCCESS">
          <i class="fa fa-fw fa-check-circle text-success" style="font-size: 200%"/><br>
          Password changed
        </div>
      </div>
    </template>
    <template slot="modal-footer" slot-scope="{ ok, cancel, close }">
      <b-button variant="secondary" @click="cancel" v-show="state === $options.STATE_SET">Dismiss</b-button>
      <busy-btn variant="primary" @click="changePw(ok)" v-show="state === $options.STATE_SET">Change password</busy-btn>
      <b-button variant="secondary" @click="close" v-show="state === $options.STATE_SUCCESS">Close</b-button>
    </template>
  </b-modal>

  
</template>

<script>
import feedback from '../lib/feedback';
import gql      from 'graphql-tag';

const STATE_SET     = 0;
const STATE_SUCCESS = 1;

export default
{
    apollo:
    {
        user: gql`{ user:whoAmI { id email } }`
    },
    computed:
    {
        pw1invalidFeedback()
        {
            if(this.newPw1.length < 8)
                return 'Enter at least 8 characters';
        },
        pw1state()
        {
            return this.pw1invalidFeedback === undefined;
        }
    },
    data()
    {
        return {
            oldPw: '',
            newPw1: '',
            newPw2: '',
            state: STATE_SET,
            user: {}
        }
    },
    methods:
    {
        async changePw(ok)
        {
            if(this.newPw1 !== this.newPw2)
            {
                await this.$bvModal.msgBoxOk('Passwords do not match', {title: 'Error', centered: true});
            }
            else
            {
                try
                {
                    await this.$apollo.mutate(
                        {
                            mutation: require('../gql/changePw.gql'),
                            variables:
                                {
                                    input: { old: this.oldPw, new: this.newPw1 }
                                }
                        }
                    );
                    this.state = STATE_SUCCESS;
                }
                catch(ex)
                {
                    await feedback(this, ex, {title: 'Error', centered: true, noCloseOnBackdrop: true});
                }
            }
        },
        reset()
        {
            this.oldPw  = '';
            this.newPw1 = '';
            this.newPw2 = '';
            this.state  = STATE_SET;
            this.pwBusy = false;
        }
    },
    props: ['value'],
    STATE_SET,
    STATE_SUCCESS
}
</script>

<style>
</style>