<template>
  <b-overlay :show="busy" rounded opacity="0.6" spinner-small :spinner-variant="spinnerVariant" :class="cssClass">
    <b-button :disabled="busy || disabled" v-bind="$attrs" @click="click" :variant="variant">
        <slot></slot>
    </b-button>
  </b-overlay>
</template>

<script>
export default
{
    data()
    {
        let invoke = this.$listeners['click']; // Direct click listener

        if(this.$attrs.type === 'submit')
        { // Form submit action
            if(this.$parent.formSubmit)
                invoke = this.$parent.formSubmit;
            else if(this.$parent.children)
                invoke = this.$parent.children[0].data.on.submit;
            else if(this.$parent.submit)
                invoke = this.$parent.submit;
            else
                invoke = function() { console.log('dummy function') };
        }

        return {
            busy: false,
            cssClass: this.$attrs.hasOwnProperty('block') ? '' : 'busy-btn-inline',
            invoke
        }
    },
    methods:
    {
        async click(event)
        {
            this.busy = true;

            try { await this.invoke(event); }
            catch (e) { throw e; }
            finally { this.busy = false; }
        }
    },
    mounted()
    {
    },
    props:
    {
        disabled:       { },
        variant:        { type: String },
        spinnerVariant: { type: String, default() { return this.variant } }
    }
}
</script>

<style>
.busy-btn-inline { display: inline-block; }
</style>