<template>
  <b-dropdown :html="text" variant="outline-secondary text-truncate" block v-b-tooltip.hover="{title:text,html:true,variant:'info'}">
    <b-dropdown-form>
      <b-form-checkbox :checked="checkboxVal.length === options.length" @change="changeAll">All</b-form-checkbox>
    </b-dropdown-form>
    <b-dropdown-divider></b-dropdown-divider>
    <b-dropdown-form>
      <b-form-checkbox-group v-model="checkboxVal" :options="options" stacked />
    </b-dropdown-form>
  </b-dropdown>
</template>

<script>
export default
{
    computed:
    {
        checkboxVal:
        {
            get() { return this.value },
            set(x) { this.$emit('input', x); }
        },
        text()
        {
            const len = this.value.length;

            if(len === this.options.length)
                return '<i>All</i>';

            if(len === 0)
                return '<i>None</i>';

            return this.value.join('+');
        }
    },
    methods:
    {
        changeAll(all)
        {
            if(all)
                this.$emit('input', this.options);
            else
                this.$emit('input', []);
        }
    },
    props:
    {
        options: { type: Array, required: true },
        value:   { }
    }
}
</script>
