<template>
  <b-form>
    <b-form-group label="PDF file with test results" :state="fields.pdf.state">
      <b-form-file v-model="fields.pdf.value"
                   placeholder="Choose a file or drop it here..."
                   drop-placeholder="Drop file here..."
                   accept="application/pdf"
                   :state="fields.pdf.state"
      />
    </b-form-group>
    <div class="d-flex">
      <b-form-group label="Condition" class="mr-5" :state="fields.condition.state">
        <b-radio-group :options="$options.conditionOptions" stacked v-model="fields.condition.value" :state="fields.condition.state" />
      </b-form-group>
      <b-form-group label="=> Advice">
        <b-form-radio-group :options="$options.adviceOptions" stacked disabled v-model="fields.advice.value" />
      </b-form-group>
    </div>
  </b-form>
</template>
<script>
import moment                   from 'moment';
import noop                     from 'lodash/noop';

import {DATE_INPUT_FMT}         from '@/lib/dateComputed';
import OilSampleTestCondition   from '@/lib/enums/OilSampleTestCondition';
import fileToBase64             from '@/lib/fileToBase64';
import {CACHE_AND_NETWORK}     from '@/lib/constants';
import {ff, ffCollectionToGql} from '@/lib/ff.mjs';

export default
{
    data()
    {
        return {
            fields:
            {
                advice: ff(),
                condition: ff(),
                pdf: ff(null, v => v !== null, fileToBase64),
            }
        }
    },
    methods:
    {
        async submitComplete(ok = noop)
        {
            let input = await ffCollectionToGql(this.fields);

            if(input === null)
                return;

            input = { ...input, id: this.id, pdfOriginalFilename: this.fields.pdf.value.name };

            await this.$apollo.mutate(
                {
                    mutation: require('../gql/mutations/completeOilSample.gql'),
                    variables: { input }
                }
            );

            await ok();
        },
    },
    watch:
    {
        'fields.condition.value'()
        {
            const cond = this.$options.conditionOptions.find(c => c.value === this.fields.condition.value);

            if(cond === undefined)
                this.fields.advice.value = null;
            else
                this.fields.advice.value = cond.advice;
        }
    },
    props:
    {
        id: { type: String, required: true }
    },
    adviceOptions:
    [
        { text: 'OK', value: 'OK', html: '<span class="text-success">OK</span>' },
        { text: 'Replace soon', value: 'REPLACE_SOON', html: '<span class="text-warning">Replace soon</span>' },
        { text: 'Replace immediately', value: 'REPLACE_IMMEDIATELY', html: '<span class="text-danger">Replace immediately</span>' }
    ],
    conditionOptions: Object.values(OilSampleTestCondition),
}
</script>