<template>
  <div>
    <b-breadcrumb class="d-none d-sm-flex">
      <b-breadcrumb-item>Service</b-breadcrumb-item>
      <b-breadcrumb-item to="/app/oilSamples">Oil samples</b-breadcrumb-item>
      <b-breadcrumb-item :to="`/app/oilSamples/${this.id}`">Details {{ourRef}}</b-breadcrumb-item>
      <b-breadcrumb-item active>Update</b-breadcrumb-item>
    </b-breadcrumb>
    <b-container>
      <b-row align-h="center">
        <b-col md="12" lg="7" xl="5">
          <div class="panel normative">
            <div class="panel-heading">
              <h3>Update</h3>
            </div>
            <div class="panel-content normative">
              <vue-element-loading :active="loading" spinner="spinner"/>
              <b-form-group label="Oil" :state="fields.oil.state" class="form-group-d-flex">
                <template v-if="withNewOil">
                  <b-input required placeholder="Name of the oil" autofocus v-model="fields.newOilName.value" :state="fields.newOilName.state" />
                  <b-btn class="ml-2" variant="outline-warning" @click="() => {fields.oil.value = null; withNewOil = false;}"><i class="fa fa-fw fa-times"></i></b-btn>
                </template>
                <template v-else>
                  <b-select :options="oilOptions" v-model="fields.oil.value" :state="fields.oil.state" @change="oilChanged">
                    <template v-slot:first>
                      <option disabled :value="null">-- Select an option --</option>
                      <option value="new" style="font-style: italic;">&nbsp;Other</option>
                    </template>
                  </b-select>
                </template>
              </b-form-group>
              <b-form-group label="Oil change" :state="fields.oilChange.state">
                <b-form-radio-group v-model="fields.oilChange.value" :options="$options.OilSampleChangeOil" stacked :state="fields.oilChange.state" @change="fields.oilChange.state = null" required />
              </b-form-group>
              <b-form-group label="Filter change" :state="fields.filterChange.state">
                <b-form-radio-group v-model="fields.filterChange.value" :options="$options.OilSampleChangeFilter" stacked :state="fields.filterChange.state" @change="fields.filterChange.state = null" required />
              </b-form-group>
              <b-form-group label="Operating time machine" :state="fields.optimeMachine.state">
                <b-input-group append="Hours">
                  <b-form-input type="number" number :state="fields.optimeMachine.state" v-model="fields.optimeMachine.value" min="0" required />
                </b-input-group>
              </b-form-group>
              <b-form-group label="Operating time oil" :state="fields.optimeOil.state" class="optime-oil-field">
                <b-input-group append="Hours">
                  <b-input type="number" number :state="fields.optimeOil.state" v-model="fields.optimeOil.value" min="0" required :disabled="optimeOilUnknown" />
                </b-input-group>
                <b-checkbox class="ml-2" v-model="optimeOilUnknown">Unknown</b-checkbox>
              </b-form-group>
              <b-form-group label="Remarks">
                <b-textarea v-model="fields.requestComment.value" />
              </b-form-group>
              <div class="panel-footer-actions">
                <busy-btn @click="submit">Save</busy-btn>
                <b-btn class="ml-1" :to="`/app/oilSamples/${id}`" variant="outline-secondary">Cancel</b-btn>
              </div>
            </div>
          </div>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import stubTrue from 'lodash/stubTrue';


import {CACHE_AND_NETWORK, NETWORK_ONLY} from '@/lib/constants';
import OilSampleChangeOil                from '@/lib/enums/OilSampleChangeOil';
import OilSampleChangeFilter   from '@/lib/enums/OilSampleChangeFilter';
import {ff, ffCollectionToGql} from '@/lib/ff.mjs';
import store2                            from '@/lib/store2';

export default
{
    apollo:
    {
        seed:
        {
            query: require('@/gql/query/oilSampleUpdateSeed.gql'),
            result({data})
            {
                this.oilOptions = data.oils.nodes;
            },
            manual: true,
            fetchPolicy: CACHE_AND_NETWORK
        }
    },
    async created()
    {
        this.$state.setTopActions({ click: this.submit, icon: 'save' });

        const {data: {oilSampleById }} = await this.$apollo.query(
            {
                query: require('@/gql/query/oilSampleForUpdate.gql'),
                fetchPolicy: NETWORK_ONLY,
                variables: { id: this.id }
            }
        );

        this.fields.oilChange.value       = oilSampleById.oilChange;
        this.fields.filterChange.value    = oilSampleById.filterChange;
        this.fields.optimeMachine.value   = oilSampleById.optimeMachine;
        this.fields.requestComment.value  = oilSampleById.requestComment;
        this.fields.oil.value             = oilSampleById.oil.id;

        if(oilSampleById.optimeOil === null)
            this.optimeOilUnknown = true;
        else
            this.fields.optimeOil.value = oilSampleById.optimeOil;

        this.ourRef                       = oilSampleById.ourRef;

        this.loading = false;
    },
    computed:
    {
        $state() { return store2(this.$pinia) },
    },
    data()
    {
        return {
            // Seed data
            oilOptions: [],
            // Form data
            fields:
            { // Validate: true = OK, false = not ok, null = no state
                oil:                    ff(null, v => this.withNewOil ? true : v !== null, v => this.withNewOil ? null : v),
                newOilName:             ff('', v => this.withNewOil ? v !== '' : true),
                optimeMachine:          ff('', v => v !== ''),
                optimeOil:              ff('', v => this.optimeOilUnknown ? true : v !== '', v => this.optimeOilUnknown ? null : v),
                oilChange:              ff(null, v => v !== null),
                filterChange:           ff(null, v => v !== null),
                requestComment:         ff('', stubTrue)
            },
            optimeOilUnknown: false,
            withNewOil: false,
            loading: true,
            ourRef: ''
        }
    },
    methods:
    {
        oilChanged(e)
        {
            this.fields.oil.state = null;
            this.fields.newOilName.state = null;

            if(e !== 'new')
                return;

            this.withNewOil = true;
        },
        async submit()
        {
            const patch = await ffCollectionToGql(this.fields);

            if(patch === null)
                return;

            try
            {
                await this.$apollo.mutate(
                    {
                        mutation: require('@/gql/mutations/updateOilSample.gql'),
                        variables: { id: this.id, patch }
                    }
                );

                return this.$router.replace(`/app/oilSamples/${this.id}`);
            }
            catch (e)
            {
                console.error(e);
            }
        },
    },
    props:
    {
        id: { type: String }
    },
    OilSampleChangeOil,
    OilSampleChangeFilter,
}
</script>

<style lang="scss">
.optime-oil-field
{
    > div
    {
        display: flex;
        align-items: center;
    }
}
</style>