<template>
  <div>
    <div class="alm" :style="left">
      <slot></slot>
    </div>
    <div @click="clickMask" class="alm-mask" v-show="visible"></div>
  </div>
</template>

<script>
export default
{
    beforeDestroy()
    {
        document.removeEventListener('touchstart', this.touchstart);
        document.removeEventListener('touchmove', this.touchmove);
        document.removeEventListener('touchend', this.touchend);
    },
    data()
    {
        return {
            left:       'transform: translate3d(-300px,0,0)',
            scrollPos:  0,
            startTime:  0,
            startX:     0,
            swiping:    0, // 0 = not swiping, 1 = swiping L<-R, 2 = swiping vertically
            visible:    false
        }
    },
    methods:
    {
        clickMask(e)
        {
            this.close();
        },
        touchmove(e)
        {
            if(this.swiping === 2)
                return;
            const [currentTouch] = e.touches;

            const distanceX = Math.abs(currentTouch.clientX - this.previousTouch.clientX);
            const distanceY = Math.abs(currentTouch.clientY - this.previousTouch.clientY);

            if(distanceY > 5 && this.swiping === 0)
            {
                this.swiping = 2;
                return;
            }

            if(distanceX > 1)
            {
                const distance = currentTouch.clientX - this.startX;

                if(distance < 0)
                { // swiping from right to left
                    this.left = `transform: translate3d(${distance}px,0,0)`;
                    this.swiping = 1;
                }
            }

            this.previousTouch = currentTouch;
        },
        touchstart(e)
        {
            const [first]       = e.touches;
            this.startX         = first.clientX;
            this.previousTouch  = e.touches[0];
            this.startTime      = Date.now();
        },
        touchend(e)
        {
            const distance  = e.changedTouches[0].clientX - this.startX;
            const time      = Date.now() - this.startTime;
            const speed     = Math.abs(distance) / time;

            if((distance < -256 / 2) || (distance < 0 && speed > 0.6))
                this.close();
            else
                this.left = 'transform: translate3d(0,0,0)';

            this.swiping = 0;
        },
        close()
        {
            this.visible = false;
            this.left = 'transform: translate3d(-300px, 0, 0)';
            document.removeEventListener('touchstart', this.touchstart);
            document.removeEventListener('touchmove', this.touchmove);
            document.removeEventListener('touchend', this.touchend);
            document.body.classList.toggle('no-body-scroll', false);
            this.$emit('input', false);

            window.scrollTo(0, this.scrollPos);
        },
        open()
        {
            this.scrollPos = document.documentElement.scrollTop;
            this.visible = true;
            this.left = 'transform: translate3d(0,0,0)';
            document.addEventListener('touchstart', this.touchstart, {passive: false});
            document.addEventListener('touchmove', this.touchmove, {passive: false});
            document.addEventListener('touchend', this.touchend, {passive: false});
            document.body.classList.toggle('no-body-scroll', true);

            document.body.style.top = -this.scrollPos + 'px';
        }
    },
    mounted()
    {
        if(this.value)
            this.$nextTick(() => this.open());
    },
    props:
    {
        value: { type: Boolean, default() { return false } }
    },
    watch:
    {
        value(val)
        {
            if(val)
                this.open();
            else
                this.close();
        }
    }
}
</script>

<style>
.no-body-scroll
{
    position: fixed;
}

div.alm
{
    background-color: #2A3F54;
    bottom: 0;
    box-shadow: 5px 0 5px 0 rgba(0,0,0,0.3);
    left: 0;
    overflow-y: auto;
    overscroll-behavior: contain;
    position: fixed;
    top: 0;
    width: 256px;
    z-index: 11;
}

div.alm-mask
{
    background-color: rgb(0, 0, 0, 0.3);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 10;
}
</style>