<template>
  <div class="col-12 align-items-center pr-0 topbar mobile-topbar">
    <a v-show="type === $options.TOPTYPE_SEARCH || type === $options.TOPTYPE_CONTEXTACTION" class="toggle-sidebar hand-on-hover" @click="regular">
      <i class="fa fa-fw fa-arrow-left"/>
    </a>
    <div v-show="type === $options.TOPTYPE_SEARCH" class="w-100 pl-3 pr-2">
      <b-input placeholder="Search" name="searchTerm" ref="searchInput" v-model="$state.topSearchText" />
    </div>
    <a @click="clearSearch" v-show="type === $options.TOPTYPE_SEARCH" class="ml-auto mr-2"><i class="fa fa-fw fa-close"></i></a>


    <a v-show="type === $options.TOPTYPE_REGULAR || type === $options.TOPTYPE_REGULAR_SEARCH" class="toggle-sidebar hand-on-hover" @click="$emit('toggleSidebar')">
      <i class="fa fa-fw fa-bars"/>
    </a>
    <div class="title" v-show="type === $options.TOPTYPE_REGULAR"><span>{{title}}</span></div>
    <a v-show="type === $options.TOPTYPE_REGULAR_SEARCH" class="ml-auto mr-2" @click="search">
      <i class="fa fa-fw fa-search"></i>
    </a>


    <div v-show="type === $options.TOPTYPE_CONTEXTACTION" class="mr-auto title"><span>{{$state.topSelected.length}} Selected</span></div>
    <a
      @click="(e) => act(action)"
      :key="idx"
      class="hand-on-hover"
      v-for="(action, idx) in $state.topActions"
      v-show="type === $options.TOPTYPE_CONTEXTACTION && action.condition($state.topSelected)">
      <i class="fa fa-fw" :class="`fa-${action.icon}`" v-if="action.icon"></i>
      <span>{{action.text}}</span>
    </a>

    <a class="toggle-sidebar hand-on-hover" v-show="type === $options.TOPTYPE_INCONTEXT" @click="inContextReturn">
      <i class="fa fa-fw fa-arrow-left"></i>
    </a>
    <div class="title" v-show="type === $options.TOPTYPE_INCONTEXT">
      <span>{{title}}</span>
      <span>{{subtitle}}</span>
    </div>

    <template v-for="(action, idx) in $state.topActions">
      <busy-btn
        @click="(e) => act(action)"
        :key="`b_${idx}`"
        class="action-button"
        variant="primary"
        v-if="type === $options.TOPTYPE_INCONTEXT && action.text"
        style="max-width: 6em;"
      >
        <span>{{action.text}}</span>
      </busy-btn>
      <a class="action-button" v-if="type === $options.TOPTYPE_INCONTEXT && action.icon && !action.disabled" @click="(e) => act(action)" target="_blank" :href="action.href">
        <i class="fa fa-fw" :class="`fa-${action.icon}`" ></i>
      </a>
    </template>
  </div>

</template>

<script>

import store2 from '@/lib/store2';
import interpretPath from '@/lib/interpretPath';

export default
{
    computed:
    {
        $state()  { return store2(this.$pinia); },
        type()    { return this.$state.topType || this.$route.meta?.topType  || this.$options.TOPTYPE_REGULAR },
        title()   { return this.$state.topTitle || this.$route.meta?.topTitle },
        subtitle(){ return this.$state.topSubtitle || this.$route.meta?.topSubtitle }
    },
    methods:
    {
        clearSearch()
        {
            this.$state.topSearchText = '';
            this.$refs.searchInput.focus();
        },
        regular()
        {
            this.$state.topReturnToRegular();
        },
        search()
        {
            this.$state.topSearch();
            this.$nextTick(this.$refs.searchInput.focus);
        },
        act(action)
        {
            if(action.href)
                return;
            else if(action.to)
                return this.$router.push(action.to(this.$state.topSelected[0]));
            else
                return action.click(this.$state.topSelected);
        },
        inContextReturn()
        {
            const returnTo      = interpretPath(this.$route.fullPath, this.$route.meta.topReturnTo);
            const topOnReturn   = this.$state.topOnReturn;

            if(topOnReturn)
            {
                if(!topOnReturn())
                    this.$router.push(returnTo);
            }
            else
            {
                this.$router.push(returnTo);
            }
        }
    },
    TOPTYPE_REGULAR: 'regular',
    TOPTYPE_REGULAR_SEARCH: 'regularSearch',
    TOPTYPE_SEARCH: 'search',
    TOPTYPE_CONTEXTACTION: 'contextAction',
    TOPTYPE_INCONTEXT: 'inContext'
}
</script>

<style>

.topbar > a
{
    margin-right: 0.5rem;
    color: rgb(115, 135, 156);
}

.topbar > a:last-child
{
    margin-right: 0.5rem;
}

.topbar > .action-button
{
    margin-left: auto !important;
}

.action-button ~ .action-button
{
    margin-left: 0 !important;
    margin-right: 0.5rem;
}
</style>