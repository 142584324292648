<template>
  <div style="height: 50vh;" class="overflow-y-scroll-force">
    <b-overlay :show="loading" rounded="sm" no-wrap />
    <b-check v-model="diffOnly">Only show differences</b-check>
    <b-check v-model="swap">Swap left/right</b-check>
    <br>
    <table class="table table-bordered table-sm diff-table">
      <thead>
        <tr>
          <th>Variable name</th>
          <th>Unit</th>
          <th>
            Left #{{leftData.seqno}} ({{leftData.date}})
            <br>{{leftData.seqno > rightData.seqno ? 'Newer' : 'Older'}}
          </th>
          <th>Right #{{rightData.seqno}} ({{rightData.date}})
            <br>{{leftData.seqno > rightData.seqno ? 'Older' : 'Newer'}}
          </th>
          <th>Change</th>
        </tr>
      </thead>
      <tbody>
        <tr>
          <td><b-input v-model="filterName" /></td>
          <td></td>
          <td></td>
          <td></td>
          <td></td>
        </tr>
        <tr v-for="line in filtered" :class="{'table-warning': line.change > 0, 'table-primary': line.change < 0}" class="text-monospace">
          <td>{{line.name}}</td>
          <td>{{line.unit}}</td>
          <td>{{line.left}}</td>
          <td>{{line.right}}</td>
          <td>{{line.change}}</td>
        </tr>
        <tr v-if="!loading && filtered.length === 0">
          <td colspan="5" class="text-center">No variables match search criteria</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import store2 from '@/lib/store2';

export default
{
    apollo:
    {
        diff:
        {
            query: require('../gql/query/plcBackupDiff.gql'),
            result({data})
            {
                this.leftData   = data.left;
                this.rightData  = data.right;
                this.loading    = false;
            },
            variables()
            {
                this.loading  = this.left !== this.right;
                const left    = this.swap ? this.right : this.left;
                const right   = this.swap ? this.left : this.right;

                return {
                    diffOnly: this.diffOnly,
                    left,
                    right,
                    tz: this.$state.timezone
                }
            },
            manual: true
        }
    },
    computed:
    {
        $state() { return store2(this.$pinia) },
        filtered()
        {
            return this.leftData.compareWith.filter(this.rowFilter(this.filterName));
        }
    },
    data()
    {
        return {
            leftData: {compareWith: []},
            rightData: {},
            diffOnly: true,
            loading: true,
            swap: false,
            filterName: ''
        }
    },
    methods:
    {
        rowFilter(input)
        {
            const fValues = input
                .toLowerCase()
                .split(' ')
                .filter(s => s !== '');

            return row =>
            {
                const name = row.name.toLowerCase();
                return fValues.every(fValue => name.includes(fValue));
            };
        }
    },
    props:
    {
        left:   { type: String, required: true },
        right:  { type: String, required: true }
    }
}
</script>

<style>
table.diff-table tbody tr td:not(:nth-child(1)),
table.diff-table thead tr th:not(:nth-child(1))
{
    text-align: right;
}

</style>