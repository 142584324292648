<template>

  <div>
    <b-breadcrumb class="d-flex align-items-center pt-1 pb-1">
      <b-breadcrumb-item>Home</b-breadcrumb-item>
      <b-breadcrumb-item active>Dashboard</b-breadcrumb-item>
      <b-breadcrumb-item>
      </b-breadcrumb-item>
      <div class="" style="width: 35em">
        <device-multi-selector/>
      </div>
      <li class="ml-auto">
        <b-button-group>
          <b-btn
            v-for="v in $options.views"
            :variant="$route.path === v.value ? 'primary' : 'outline-primary'"
            :to="v.value"
            :key="`v_${v.value}`">
            {{v.text}}
          </b-btn>
        </b-button-group>
      </li>
    </b-breadcrumb>
    <router-view/>
  </div>

</template>

<script>
import store2              from '@/lib/store2';
import DeviceMultiSelector from '@/components/DeviceMultiSelector.vue';


export default
{
    created()
    {
        this.$state.topRegular('Dashboard', false);
    },
    components: {DeviceMultiSelector },
    computed:
    {
        $state() { return store2(this.$pinia) }
    },
    data()
    {
        return {
            devices: { nodes: [] }
        }
    },
    views:
    [
        { text: 'Full', value: '/app/dashboard/full' },
        { text: 'Mini', value: '/app/dashboard/mini' },
        { text: 'Map', value: '/app/dashboard/map' }
    ]
}
</script>

<style>
</style>